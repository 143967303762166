import moment from 'moment';

import { convertLocalMomentToUTCDateTime } from '../utils/convertDate';

import {
    convertCreateInterviewDetailsToCreateInterviewDetailsRequest,
    convertInterviewResponseToInterview
} from './convert/interview';
import { get, post, put } from './http';

import type { InterviewStatus } from '../types/enum';
import type {
    CreateInterviewDetails,
    InterviewResponse
} from '../types/interview';

export const createInterview = (
    interviewDetails: CreateInterviewDetails,
    jobID: number
) =>
    post(
        '/api/interview/',
        convertCreateInterviewDetailsToCreateInterviewDetailsRequest(
            interviewDetails,
            jobID
        )
    );

export const getInterview = (interviewID: number, userID: number) =>
    get(`/api/interview/${interviewID}/${userID}`).then(
        (response: InterviewResponse) =>
            convertInterviewResponseToInterview(response)
    );

export const getInterviewsForUser = (userID: number) =>
    get(`/api/interview/user/${userID}`).then((response: InterviewResponse[]) =>
        response.map(convertInterviewResponseToInterview)
    );

export const getInterviewSession = (sessionID: string) =>
    get(`/api/interview/session/${sessionID}`);

export const bookInterview = (interviewID: number, userID: number) =>
    put('/api/interview/book', { interview_id: interviewID, user_id: userID });

export const cancelInterview = (
    interviewID: number,
    cancelledByCandidate: boolean
) =>
    put('/api/interview/cancel', {
        interview_id: interviewID,
        cancelled_by_candidate: cancelledByCandidate
    });

export const cancelInterviews = (interviewIDs: number[]) =>
    put('/api/interview/cancel/multiple', { interview_ids: interviewIDs });

export const completeInterview = (
    interviewID: number,
    completedDuration: number
) =>
    put('/api/interview/complete', {
        interview_id: interviewID,
        completed_duration: completedDuration
    });

export const setUserPresent = (
    interviewID: number,
    isPresent: boolean,
    isInterviewer: boolean
) =>
    put('/api/interview/present', {
        interview_id: interviewID,
        is_present: isPresent,
        is_interviewer: isInterviewer
    });

export const provideInterviewFeedback = (
    interviewID: number,
    feedback: {
        id: number;
        questionID: number;
        answer: string;
    }[],
    answeredByCandidate: boolean
) =>
    put('/api/interview/feedback', {
        interview_id: interviewID,
        feedback: feedback.map((f) => ({
            id: f.id,
            question_id: f.questionID,
            answer: f.answer
        })),
        answered_by_candidate: answeredByCandidate
    });

export const createFollowUpInterviews = (
    jobID: number,
    interviewerID: number,
    startTimes: moment.Moment[],
    duration: number,
    candidateID: number
) =>
    post('/api/interview/follow_up', {
        job_id: jobID,
        interviewer_id: interviewerID,
        start_times: startTimes.map(convertLocalMomentToUTCDateTime),
        duration,
        candidate_id: candidateID
    });

export const updateInterviewStatus = (
    interviewID: number,
    status: InterviewStatus
) => put('/api/interview/status', { interview_id: interviewID, status });
