/* eslint-disable max-lines */
import BarChartIcon from '@mui/icons-material/BarChart';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import { CircularProgress, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
    getCompanyJobs,
    getJobOffers,
    updateJob
} from '../../../../services/jobs';
import Button, { TextButton } from '../../../global/components/Button/Button';

import EmployerJobCard from './EmployerJobCard';

import type { Company } from '../../../../types/company';
import type { SimpleInterview } from '../../../../types/interview';
import type { Job, JobOffer } from '../../../../types/job';
import type { User } from '../../../../types/user';

const CompanyHeader = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    align-items: center;
    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1.5rem;
        margin-bottom: 1.5rem;
    }
`;

const InfoBar = styled('div')(
    ({ theme }) => `
        background-color: ${theme.palette.primary.light};
        color: ${theme.palette.primary.main};
        border-radius: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-weight: 500;
        min-height: 2.5rem;
    `
);

type JobsByStatus = {
    active: Job[];
    pending: Job[];
    completed: Job[];
    closed: Job[];
};

const getOpenUpcomingInterviews = (interviews: SimpleInterview[]) =>
    interviews.filter(
        (interview) =>
            [
                'available',
                'scheduled',
                'follow_up_requested',
                'reschedule_requested'
            ].includes(interview.status) &&
            interview.startTime.isAfter(moment())
    );

const groupByStatus = (jobs: Job[], offers: JobOffer[]) => {
    const jobsWithOffers = jobs.map((job) => ({
        ...job,
        offers: [] as JobOffer[],
        offerAccepted: false
    }));
    offers.forEach((offer) => {
        const jobIndex = jobsWithOffers.findIndex(
            (job) => job.id === offer.jobID
        );
        if (jobIndex !== -1) {
            jobsWithOffers[jobIndex].offers.push(offer);
        }
        if (offer.status === 'accepted') {
            jobsWithOffers[jobIndex].offerAccepted = true;
        }
    });
    const jobsByStatus = {
        active: [],
        pending: [],
        completed: [],
        closed: []
    } as JobsByStatus;
    jobsWithOffers.forEach((job) => {
        const upcomingInterviews = getOpenUpcomingInterviews(job.interviews);
        if (!job.open) {
            jobsByStatus.closed.push(job);
        } else if (job.offerAccepted) {
            jobsByStatus.completed.push(job);
        } else if (upcomingInterviews.length > 0) {
            jobsByStatus.active.push(job);
        } else {
            jobsByStatus.pending.push(job);
        }
    });
    return jobsByStatus;
};

const LoadingWheel = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center'
        }}
    >
        <CircularProgress />
    </div>
);

export default function EmployerJobList({
    user,
    company
}: {
    user: User;
    company: Company;
}) {
    const [jobs, setJobs] = useState<Job[]>([]);
    const [offers, setOffers] = useState<JobOffer[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showJobs, setShowJobs] = useState({
        active: { expanded: false, jobs: 3 },
        pending: { expanded: false, jobs: 2 },
        completed: { expanded: false, jobs: 1 }
    });

    const subRoleCount = company.subscription?.activeRoles || 0;

    const jobSections = useMemo(() => {
        const { active, pending, completed, closed } = groupByStatus(
            jobs,
            offers
        );

        return {
            active: {
                title: 'Active Roles',
                tooltip:
                    'Active roles count against your subscription and represent roles that currently have upcoming interviews.',
                jobs: active
            },
            pending: {
                title: 'Pending Decision',
                tooltip:
                    'Roles that no longer have any active upcoming interviews but do not have an accepted job offer.',
                jobs: pending
            },
            completed: {
                title: 'Completed',
                tooltip:
                    'Roles that have had an accepted job offer or are no longer open for applications.',
                jobs: completed.concat(closed)
            }
        };
    }, [jobs, offers]);

    const updateExpandedState = (section: keyof typeof showJobs) => {
        setShowJobs({
            ...showJobs,
            [section]: {
                jobs: showJobs[section].expanded
                    ? 0
                    : jobSections[section].jobs.length,
                expanded: !showJobs[section].expanded
            }
        });
    };

    const setJobOpen = (jobID: number, open: boolean) => {
        setJobs(
            jobs.map((job) => {
                if (job.id === jobID) {
                    updateJob({ ...job, open });
                    return { ...job, open };
                }
                return job;
            })
        );
    };

    useEffect(() => {
        if (company.id) {
            setIsLoading(true);
            getCompanyJobs(company.id)
                .then((response: Job[]) => {
                    setJobs(response);
                    setIsLoading(false);
                })
                .catch((err: any) => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                    setIsLoading(false);
                });
        }
        if (user.id) {
            getJobOffers(user.id).then((response: JobOffer[]) => {
                setOffers(response);
            });
        }
    }, [company.id, user.id, jobs.length, offers.length]);

    useEffect(() => {
        (
            [
                'active',
                'pending',
                'completed'
            ] as unknown as (keyof typeof showJobs)[]
        ).forEach((section) => {
            setShowJobs((prev) => ({
                ...prev,
                [section]: prev[section].expanded
                    ? {
                          jobs: jobSections[section].jobs.length,
                          expanded: true
                      }
                    : {
                          jobs: prev[section].jobs,
                          expanded: false
                      }
            }));
        });
    }, [jobSections]);

    if (isLoading) return <LoadingWheel />;

    return (
        <>
            <CompanyHeader>
                <div key={company.id}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem'
                        }}
                    >
                        <Typography variant={'h2'} color={'secondary'}>
                            {`${company.name} Jobs`}
                        </Typography>
                        <Link
                            to={`/secure/${user.userID}/statistics/${company.id}`}
                        >
                            <IconButton
                                color={'primary'}
                                disabled={!company.subscription}
                            >
                                <BarChartIcon />
                            </IconButton>
                        </Link>
                        <Link
                            to={`/edit/${user.userID}/business/${company.id}/companypage`}
                        >
                            <IconButton color={'primary'}>
                                <SettingsIcon />
                            </IconButton>
                        </Link>
                    </div>
                    <Typography
                        variant='body2'
                        style={{
                            display: 'flex',
                            marginTop: '0.875rem'
                        }}
                    >
                        {'You have '}
                        <Typography
                            style={{
                                marginLeft: '0.25rem',
                                marginRight: '0.25rem'
                            }}
                            color='primary'
                            variant='body2'
                            component='span'
                        >
                            <b>{jobSections.active.jobs.length}</b>
                        </Typography>
                        {'active job postings.'}
                    </Typography>
                </div>
                <div>
                    <Button
                        to={`/create/${user.userID}/job/${company.id}/intro`}
                        variant='contained'
                        disabled={
                            !company.subscription ||
                            subRoleCount <= jobSections.active.jobs.length
                        }
                    >
                        {'Post a job'}
                    </Button>
                </div>
            </CompanyHeader>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    marginTop: '1rem',
                    marginBottom: '1rem'
                }}
            >
                {(jobs.length === 0 || !company.subscription) && (
                    <InfoBar>
                        {!company.subscription
                            ? 'Your subscription is inactive, please renew your subscription.'
                            : "You don't have any jobs postings yet. Create your first role!"}
                        {!company.subscription && (
                            <TextButton
                                to={`/edit/${user.userID}/business/${company.id}/plans`}
                                style={{
                                    fontSize: '1rem'
                                }}
                            >
                                {'Update Subscription'}
                            </TextButton>
                        )}
                    </InfoBar>
                )}
                {!(jobs.length === 0 || !company.subscription) &&
                    subRoleCount <= jobSections.active.jobs.length && (
                        <InfoBar>
                            {`You have reached your active role limit of ${subRoleCount}.`}
                            <TextButton
                                to={`/edit/${user.userID}/business/${company.id}/plans`}
                                style={{
                                    fontSize: '1rem'
                                }}
                            >
                                {'Update Subscription'}
                            </TextButton>
                        </InfoBar>
                    )}
                {(
                    [
                        'active',
                        'pending',
                        'completed'
                    ] as unknown as (keyof typeof jobSections)[]
                ).map((section) => (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                                justifyContent: 'space-between',
                                marginRight: '1rem'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0rem'
                                }}
                            >
                                <Typography variant={'h2'} color={'secondary'}>
                                    {jobSections[section].title}
                                </Typography>
                                <Tooltip
                                    title={jobSections[section].tooltip}
                                    placement='right'
                                >
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <TextButton
                                onClick={() => updateExpandedState(section)}
                            >
                                {showJobs[section].expanded
                                    ? 'Collapse'
                                    : 'Expand'}
                            </TextButton>
                        </div>
                        {jobSections[section].jobs
                            .slice(0, showJobs[section].jobs)
                            .map((job, index) => (
                                <EmployerJobCard
                                    key={index}
                                    job={job}
                                    userID={user.userID}
                                    active={!!company.subscription}
                                    status={
                                        section === 'completed' && !job.open
                                            ? 'closed'
                                            : section
                                    }
                                    setJobOpen={(open) =>
                                        setJobOpen(job.id, open)
                                    }
                                    activeRolesExceeded={
                                        subRoleCount <=
                                        jobSections.active.jobs.length
                                    }
                                />
                            ))}
                    </>
                ))}
            </div>
        </>
    );
}
