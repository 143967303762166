import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextButton } from '../../../global/components/Button/Button';
import TextInput from '../../../global/components/TextField/TextField';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import { FormSection, NextSteps, StyledButton } from '../styles';

import content from './content';

import type { User } from '../../../../types/user';
import type { Control, FieldValues } from 'react-hook-form';

type StringEducation = {
    title: string;
    institution: string;
    startDate: string;
    endDate: string;
};

const formSchema = yup.object().shape({
    education: yup.array().of(
        yup.object().shape({
            title: yup.string(),
            institution: yup.string(),
            startDate: yup.string(),
            endDate: yup.string()
        })
    )
});

const convertMomentToString = (data: User['education']) =>
    data.map((education) => ({
        ...education,
        startDate: education.startDate.format('YYYY-MM-DD'),
        endDate: education.endDate.format('YYYY-MM-DD')
    }));

const convertStringToMoment = (data: StringEducation[]) =>
    data.map((education) => ({
        ...education,
        startDate: moment(education.startDate, 'YYYY-MM-DD'),
        endDate: moment(education.endDate, 'YYYY-MM-DD')
    }));

export default function EducationSlide(props: {
    profile: User['education'];
    updateProfile: (value: User['education']) => void;
    prevURL: string;
    onClose: () => Promise<void>;
    isEdit?: boolean;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: { education: convertMomentToString(props.profile) }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'education'
    });

    const onSubmit = (data: { education: StringEducation[] }) => {
        props.updateProfile(convertStringToMoment(data.education));
    };

    return (
        <ColumnCard
            closeScreen
            step={3}
            screenType={'candidate'}
            onClose={props.onClose}
            id={'educationSlide'}
            wide
            hideImage
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {commonContent.common.title}
                </Typography>
                <Typography>
                    {'Tell us about any education you have received.'}
                </Typography>
                {fields.map((field, index) => (
                    <div key={field.id} style={{ display: 'flex' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid #C2C2C2',
                                padding: '1rem',
                                borderRadius: '0.5rem',
                                marginTop: '1rem',
                                flex: 1
                            }}
                        >
                            <TextInput
                                id={`education.${index}.title`}
                                label={content.education.title.label}
                                color='primary'
                                error={!!errors.education?.[index]?.title}
                                errorText={
                                    errors.education?.[index]?.title?.message
                                }
                                placeholder={
                                    content.education.title.placeholder
                                }
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                            />
                            <TextInput
                                id={`education.${index}.institution`}
                                label={content.education.institution.label}
                                color='primary'
                                error={!!errors.education?.[index]?.institution}
                                errorText={
                                    errors.education?.[index]?.institution
                                        ?.message
                                }
                                placeholder={
                                    content.education.institution.placeholder
                                }
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '1rem',
                                    paddingTop: '0.75rem',
                                    paddingBottom: '0.5rem'
                                }}
                            >
                                <Controller
                                    name={`education.${index}.startDate`}
                                    control={control}
                                    render={({
                                        field: { onChange, value }
                                    }) => (
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                        >
                                            <DatePicker
                                                label='Start date'
                                                format={'YYYY-MM-DD'}
                                                value={moment(
                                                    value,
                                                    'YYYY-MM-DD'
                                                )}
                                                onChange={(newValue) =>
                                                    onChange(
                                                        newValue?.format(
                                                            'YYYY-MM-DD'
                                                        )
                                                    )
                                                }
                                                sx={{ flex: 1 }}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                                <Controller
                                    name={`education.${index}.endDate`}
                                    control={control}
                                    render={({
                                        field: { onChange, value }
                                    }) => (
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                        >
                                            <DatePicker
                                                label='End date'
                                                format={'YYYY-MM-DD'}
                                                value={moment(
                                                    value,
                                                    'YYYY-MM-DD'
                                                )}
                                                onChange={(newValue) =>
                                                    onChange(
                                                        newValue?.format(
                                                            'YYYY-MM-DD'
                                                        )
                                                    )
                                                }
                                                sx={{ flex: 1 }}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </div>
                        </div>
                        {fields.length > 1 && (
                            <IconButton
                                id={`education.${index}.remove`}
                                color='primary'
                                aria-label={content.education.remove}
                                component='label'
                                sx={{ ml: 1 }}
                                onClick={() => remove(index)}
                            >
                                <HighlightOffIcon />
                            </IconButton>
                        )}
                    </div>
                ))}
                <div style={{ marginTop: '1rem', paddingLeft: '1rem' }}>
                    <TextButton
                        id={'addEducation'}
                        startIcon={<AddCircleOutlineIcon />}
                        sx={{ fontWeight: 400, mt: 2 }}
                        onClick={() =>
                            append({
                                startDate: '',
                                endDate: '',
                                title: '',
                                institution: ''
                            })
                        }
                    >
                        {content.education.add}
                    </TextButton>
                </div>
                <NextSteps>
                    {!props.isEdit && (
                        <TextButton to={props.prevURL}>
                            {commonContent.common.back}
                        </TextButton>
                    )}
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id='submit'
                    >
                        {props.isEdit ? 'Save' : commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
