import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useParams,
    useRouteMatch
} from 'react-router-dom';

import { User } from '../../../../types/user';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { UserContext } from '../../../context/UserContext';
import MainContainer from '../../../global/layouts/MainContainer/MainContainer';
import {
    useBusinessProfileSidebarButtons,
    useDefaultSidebarButtons
} from '../../../global/layouts/MainContainer/SidebarButton/SidebarList';
import LocationSlide from '../common/LocationSlide';
import NameSlide from '../common/NameSlide';

import BusinessDescrption from './BusinessDescription';
import BusinessInfo from './BusinessInfo';
import BusinessPlans from './BusinessPlans';

import type { Company } from '../../../../types/company';

const routes = {
    name: { next: 'location', prev: 'plans' },
    location: { next: 'companypage', prev: 'name' },
    companypage: { next: 'companydescription', prev: 'location' },
    companydescription: { next: 'plans', prev: 'companypage' },
    plans: { next: 'name', prev: 'companydescription' }
};

export default function BusinessProfile() {
    const [redirect, setRedirect] = useState<string>('');
    const location = useLocation();
    const { path } = useRouteMatch();
    const { companyID } = useParams<{ companyID?: string }>();
    const {
        user,
        setUser,
        updateUser,
        company,
        setCompany,
        setCompanyID,
        createOrUpdateCompany
    } = useContext(UserContext);
    const { auth } = useContext(FirebaseContext);
    const defaultSideBarButtons = useDefaultSidebarButtons(user.userID);
    const scrollButtons = useBusinessProfileSidebarButtons(
        user.userID,
        companyID ? parseInt(companyID, 10) : undefined
    );

    useEffect(() => {
        if (companyID) {
            setCompanyID(parseInt(companyID, 10));
        }
    }, [companyID, setCompanyID]);

    const flowType = useMemo(
        () => (location.pathname.includes('/edit/') ? 'edit' : 'create'),
        [location.pathname]
    );

    const getPageURL = (
        page: keyof typeof routes,
        reverse: boolean = false
    ) => {
        let baseURL = `/${flowType}/${user.userID}/business`;
        if (flowType === 'edit') {
            baseURL += `/${company.id}`;
        }
        if (reverse) {
            return `${baseURL}/${routes[page].prev}`;
        }
        return `${baseURL}/${routes[page].next}`;
    };

    const update = (key: 'name' | 'location', value: User[keyof User]) => {
        setUser({ ...user, [key]: value });
        if (flowType !== 'edit') {
            setRedirect(
                getPageURL(
                    key === 'name' ? 'name' : 'location',
                    key === 'location'
                )
            );
        }
    };

    const submitUser = (updatedData: Partial<User>) => {
        updateUser(updatedData).then(() => {
            if (flowType !== 'edit') {
                setRedirect(getPageURL('location'));
            }
        });
    };

    const userOnClose = () =>
        updateUser().then(() => {
            setRedirect(`/secure/${user.userID}/business/dashboard`);
        });

    const submitCompany = (updatedCompany?: Partial<Company>) =>
        createOrUpdateCompany(updatedCompany).then(() => {
            if (flowType !== 'edit') {
                if (location.pathname.includes('companydescription')) {
                    setRedirect(getPageURL('companydescription'));
                } else if (location.pathname.includes('plans')) {
                    setRedirect(`/secure/${user.userID}/business/dashboard`);
                }
            }
        });

    const companyOnClose = () =>
        createOrUpdateCompany().then(() => {
            setRedirect(`/secure/${user.userID}/business/dashboard`);
        });

    useEffect(() => {
        if (redirect) {
            setRedirect('');
        }
    }, [redirect]);

    useEffect(() => {
        if (
            location.pathname.includes('business') &&
            user.levylRole !== 'business'
        ) {
            setUser({
                ...user,
                levylRole: 'business'
            });
        }
    }, [location, user, setUser]);

    const BusinessProfileRoutes = () => (
        <Switch>
            <Route path={[`${path}/name`, '/edit/:uid/business/name']}>
                <NameSlide
                    profile={user.name}
                    updateProfile={(value) => update('name', value)}
                    userRole={'business'}
                    onClose={userOnClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={[`${path}/location`, '/edit/:uid/business/location']}>
                <LocationSlide
                    profile={user.location}
                    updateProfile={(value) => update('location', value)}
                    userRole={'business'}
                    submit={submitUser}
                    prevURL={getPageURL('location', true)}
                    onClose={userOnClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/companypage`}>
                <BusinessInfo
                    company={company}
                    setCompanyProfile={(updatedCompany: Partial<Company>) => {
                        setCompany(updatedCompany);
                        if (flowType !== 'edit') {
                            setRedirect(getPageURL('companypage'));
                        }
                    }}
                    prevURL={getPageURL('companypage', true)}
                    onClose={() =>
                        Promise.resolve(
                            setRedirect(
                                `/secure/${user.userID}/business/dashboard`
                            )
                        )
                    }
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/companydescription`}>
                <BusinessDescrption
                    company={company}
                    submitCompany={submitCompany}
                    prevURL={getPageURL('companydescription', true)}
                    onClose={companyOnClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/plans`}>
                <BusinessPlans
                    company={company}
                    prevURL={`/secure/${user.userID}/${user.levylRole}/dashboard`}
                    onClose={companyOnClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
        </Switch>
    );

    return (
        <Fragment>
            {flowType === 'edit' ? (
                <MainContainer
                    sideBarButtons={defaultSideBarButtons}
                    firstName={user.name.firstName}
                    photoURL={user.name.imageURL}
                    signOut={() => {
                        auth?.signOut();
                    }}
                    scrollButtons={scrollButtons}
                >
                    <BusinessProfileRoutes />
                </MainContainer>
            ) : (
                <MainContainer public fullWidth>
                    <BusinessProfileRoutes />
                </MainContainer>
            )}
            {redirect && <Redirect to={redirect} />}
        </Fragment>
    );
}
