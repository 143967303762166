import { IconButton } from '@mui/material';
import React from 'react';

const SocialShareButton = ({
    icon: Icon,
    baseUrl,
    message
}: {
    icon: any;
    baseUrl: string;
    message: string;
}) => {
    const encodedMessage = encodeURIComponent(message);
    const shareUrl = `${baseUrl}${encodedMessage}`;

    return (
        <IconButton
            component='a'
            href={shareUrl}
            target='_blank'
            rel='noopener noreferrer'
            aria-label='share'
            color='primary'
        >
            <Icon />
        </IconButton>
    );
};

export default SocialShareButton;
