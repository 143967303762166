import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    useHistory,
    useLocation,
    useParams,
    useRouteMatch
} from 'react-router-dom';

import { createEmptyJob } from '../../../services/convert/job';
import { createInterview } from '../../../services/interviews';
import { createJob, getJob, updateJob } from '../../../services/jobs';
import { getEligibleUserCount } from '../../../services/user';
import { CreateInterviewDetails } from '../../../types/interview';
import { CandidateSearch, SimpleJob } from '../../../types/job';
import { FirebaseContext } from '../../context/FirebaseContext';
import { UserContext } from '../../context/UserContext';
import MainContainer from '../../global/layouts/MainContainer/MainContainer';
import {
    useCreateJobSidebarButtons,
    useDefaultSidebarButtons
} from '../../global/layouts/MainContainer/SidebarButton/SidebarList';

import JobPostRoutes, { routes } from './JobPostRoutes';

export default function JobPost() {
    const [redirect, setRedirect] = useState<string>('');
    const location = useLocation();
    const { path } = useRouteMatch();
    const history = useHistory();
    const { companyID, jobID } =
        useParams<{ uid: string; companyID: string; jobID: string }>();
    const [query, setQuery] = useState<CandidateSearch>({});

    const [candidateCount, setCandidateCount] = useState(0);

    const {
        user,
        company: currentCompany,
        setCompanyID
    } = useContext(UserContext);
    const { auth } = useContext(FirebaseContext);

    const [job, setJob] = useState<SimpleJob>(createEmptyJob());

    const defaultSideBarButtons = useDefaultSidebarButtons(user.userID);
    const scrollButtons = useCreateJobSidebarButtons(
        user.userID,
        currentCompany.id,
        job.id
    );

    useEffect(() => {
        if (companyID) {
            setCompanyID(parseInt(companyID, 10));
        }
    }, [companyID, setCompanyID]);

    useEffect(() => {
        if (redirect) {
            history.push(redirect);
            setRedirect('');
        }
    }, [redirect, history]);

    const flowType = useMemo(
        () => (location.pathname.includes('/edit/') ? 'edit' : 'create'),
        [location.pathname]
    );

    const getPageURL = (page: keyof typeof routes) => {
        let baseURL = `/${flowType}/${user.userID}/job/${currentCompany.id}`;
        if (flowType === 'edit') {
            baseURL += `/${job.id}`;
        }
        return `${baseURL}/${page}`;
    };

    useEffect(() => {
        if (jobID) {
            getJob(parseInt(jobID, 10)).then((jobResponse: SimpleJob) => {
                setJob(jobResponse);
            });
        }
    }, [jobID, setJob]);

    const setJobAndRedirect = (
        newJob: SimpleJob,
        page: keyof typeof routes
    ) => {
        setJob(newJob);
        if (flowType === 'create') {
            setRedirect(getPageURL(page));
        }
    };

    const submitJob = (jobDetails: SimpleJob) => {
        if (job.id !== 0) {
            updateJob(jobDetails).then((response: SimpleJob) => {
                setJob(response);
                if (flowType === 'create') {
                    setRedirect(getPageURL('schedule'));
                }
            });
        } else {
            createJob({
                ...jobDetails,
                companyID: currentCompany.id,
                createdByID: user.id
            }).then((jobResponse: SimpleJob) => {
                setJob(jobResponse);
                if (flowType === 'create') {
                    setRedirect(getPageURL('schedule'));
                }
            });
        }
    };

    const submitInterview = (
        interviewDetails: CreateInterviewDetails['details']
    ) => {
        createInterview(
            {
                jobID: job.id,
                interviewerID: user.id,
                split: true,
                splitTime: 8,
                details: interviewDetails
            },
            job.id
        ).then(() => {
            if (flowType === 'create') {
                setRedirect(getPageURL('complete'));
            }
        });
    };

    useEffect(() => {
        getEligibleUserCount({}).then((count: number) => {
            setCandidateCount(count);
        });
    }, [setCandidateCount]);

    const onClose = async () => {
        if (job.id !== 0) {
            updateJob(job).then((response: SimpleJob) => {
                setJob(response);
                setRedirect(
                    `/secure/${user.userID}/${user.levylRole}/dashboard`
                );
            });
        } else {
            setRedirect(`/secure/${user.userID}/${user.levylRole}/dashboard`);
        }
    };

    return (
        <Fragment>
            {location.pathname.includes('/edit/') ? (
                <MainContainer
                    sideBarButtons={defaultSideBarButtons}
                    firstName={user.name.firstName}
                    photoURL={user.name.imageURL}
                    signOut={() => {
                        auth?.signOut();
                    }}
                    scrollButtons={scrollButtons}
                >
                    <JobPostRoutes
                        path={path}
                        job={job}
                        setJobAndRedirect={setJobAndRedirect}
                        candidateCount={candidateCount}
                        setCandidateCount={setCandidateCount}
                        query={query}
                        setQuery={setQuery}
                        submitJob={submitJob}
                        submitInterview={submitInterview}
                        getPageURL={getPageURL}
                        onClose={onClose}
                        isEdit={flowType === 'edit'}
                    />
                </MainContainer>
            ) : (
                <MainContainer public fullWidth>
                    <JobPostRoutes
                        path={path}
                        job={job}
                        setJobAndRedirect={setJobAndRedirect}
                        candidateCount={candidateCount}
                        setCandidateCount={setCandidateCount}
                        query={query}
                        setQuery={setQuery}
                        submitJob={submitJob}
                        submitInterview={submitInterview}
                        getPageURL={getPageURL}
                        onClose={onClose}
                        isEdit={flowType === 'edit'}
                    />
                </MainContainer>
            )}
        </Fragment>
    );
}
