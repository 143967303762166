import { post } from './http';

export const createCandidateMocks = (
    userID: string,
    email: string,
    apiKey: string
) =>
    post(`/api/admin/mocks/candidate?api_key=${apiKey}`, {
        user_id: userID,
        email
    });

export const createWaitlistCandidateMock = (apiKey: string) =>
    post(`/api/admin/mocks/waitlist-candidate?api_key=${apiKey}`, {});

export const createCompanyMocks = (
    userID: string,
    email: string,
    apiKey: string
) =>
    post(`/api/admin/mocks/manager?api_key=${apiKey}`, {
        user_id: userID,
        email
    });

export const connectMockCandidateToMockManager = (
    candidateID: string,
    managerID: string,
    candidateEmail: string,
    managerEmail: string,
    apiKey: string
) =>
    post(`/api/admin/mocks/connect?api_key=${apiKey}`, {
        candidate_id: candidateID,
        manager_id: managerID,
        candidate_email: candidateEmail,
        manager_email: managerEmail
    });

export const populateMockJobs = (count: number, apiKey: string) =>
    post(`/api/admin/mocks/populate/${count}?api_key=${apiKey}`, {});

export const dropAllMockData = (apiKey: string) =>
    post(`/api/admin/mocks/drop?api_key=${apiKey}`, {});
