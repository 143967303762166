import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { styled, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

import Button, { TextButton } from '../../components/Button/Button';
import { BaseCard, ProfilePicture } from '../../Core';

export const StyledButton = styled(Button)`
    margin-top: 1.5rem;
`;

export const StyledTextButton = styled(TextButton)`
    margin-top: 0.5rem;
`;

export const StyledSmallCard = styled(BaseCard)`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    position: relative;
`;

const DrawerIconContainer = styled(IconButton)`
    position: absolute;
    top: 10px;
    right: 10px;
`;

export type SmallCardProps = {
    imageSrc: string;
    children?: React.ReactNode;
    statusText: string;
    primaryButton?: {
        text: string;
        onClick?: () => void;
        to?: string;
    };
    secondaryButton?: {
        text: string;
        onClick?: () => void;
        to?: string;
    };
    drawerActions?: {
        text: string;
        onClick: () => void;
    }[];
};

export default function SmallCard({
    imageSrc,
    children,
    statusText,
    primaryButton,
    secondaryButton,
    drawerActions
}: SmallCardProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <StyledSmallCard variant='outlined'>
            {drawerActions && (
                <>
                    <DrawerIconContainer onClick={handleClick}>
                        <LinearScaleIcon />
                    </DrawerIconContainer>
                    <Menu
                        id='demo-customized-menu'
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button'
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        disableScrollLock
                    >
                        {drawerActions.map((action, index) => (
                            <MenuItem key={index} onClick={action.onClick}>
                                <Typography>{action.text}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}
            <ProfilePicture size={2} src={imageSrc} />
            {children}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '0.25rem'
                }}
            >
                <AccessTimeIcon sx={{ mr: 0.5 }} fontWeight={'400'} />
                <Typography
                    variant={'body2'}
                    fontWeight={'500'}
                    style={{ textAlign: 'center' }}
                >
                    {statusText}
                </Typography>
            </div>
            {primaryButton && (
                <StyledButton
                    variant='contained'
                    onClick={primaryButton.onClick}
                    to={primaryButton.to}
                >
                    {primaryButton.text}
                </StyledButton>
            )}
            {secondaryButton && (
                <StyledTextButton
                    variant='text'
                    onClick={secondaryButton.onClick}
                    to={secondaryButton.to}
                    color='secondary'
                >
                    {secondaryButton.text}
                </StyledTextButton>
            )}
        </StyledSmallCard>
    );
}
