import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

import confirmImage from '../../../../static/illustrations/profile-confirm.svg';
import Button from '../../../global/components/Button/Button';
import SocialShareButton from '../../../global/components/SocialShareButton/SocialShareButton';

export const StyledCard = styled(Card)<{ isComponent: boolean }>(
    ({ isComponent }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 2rem;
    position: relative;
    ${!isComponent && 'flex: 1;'}
`
);

export const StyledContent = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
    }
}));

export const StyledImage = styled('img')(() => ({
    maxWidth: '100%',
    maxHeight: '30rem'
}));

const StyledButton = styled(Button)`
    margin-top: 1.5rem;
`;

export default function ShareSlide(props: {
    id?: string;
    isComponent?: boolean;
    buttonText: string;
    buttonLink: string;
}) {
    return (
        <>
            <StyledCard isComponent={props.isComponent} id={props.id}>
                <StyledContent>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <StyledImage src={confirmImage} />
                        <Typography
                            align='center'
                            sx={{ mt: 3 }}
                            variant={'h1'}
                            fontSize={'1.5rem'}
                        >
                            {'Success! Your profile is now in the job pool'}
                        </Typography>
                        <Typography align='center' sx={{ mt: 1 }}>
                            {
                                'Let us take care of the rest, we will reach out to you when we find a job that fits your profile'
                            }
                        </Typography>
                        <Typography
                            align='center'
                            sx={{ mt: 4, fontSize: '1rem', fontWeight: 500 }}
                        >
                            {
                                'Help your friends and family find a job by sharing Levyl with them'
                            }
                        </Typography>
                        <div style={{ marginTop: '0.5rem' }}>
                            <SocialShareButton
                                icon={TwitterIcon}
                                baseUrl={
                                    'https://twitter.com/intent/tweet?text='
                                }
                                message={
                                    'Join a platform where jobs come to you: https://levyl.app/public/career-seekers'
                                }
                            />
                            <SocialShareButton
                                icon={LinkedInIcon}
                                baseUrl={
                                    'https://www.linkedin.com/sharing/share-offsite/?url='
                                }
                                message={
                                    'https://levyl.app/public/career-seekers'
                                }
                            />
                            <SocialShareButton
                                icon={FacebookIcon}
                                baseUrl={
                                    'https://www.facebook.com/sharer/sharer.php?u='
                                }
                                message={
                                    'https://levyl.app/public/career-seekers'
                                }
                            />
                        </div>
                        <div>
                            <StyledButton
                                variant={'outlined'}
                                to={props.buttonLink}
                            >
                                {props.buttonText}
                            </StyledButton>
                        </div>
                    </div>
                </StyledContent>
            </StyledCard>
        </>
    );
}
