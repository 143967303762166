/* eslint-disable max-lines */
import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useRouteMatch
} from 'react-router-dom';

import {
    checkWaitlist,
    getParsedResumeResult,
    parseResume
} from '../../../../services/user';
import waitImage from '../../../../static/illustrations/balloon.svg';
import updateDataLayer from '../../../../utils/updateDataLayer';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { UserContext } from '../../../context/UserContext';
import MainContainer from '../../../global/layouts/MainContainer/MainContainer';
import {
    useCandidateProfileSidebarButtons,
    useDefaultSidebarButtons
} from '../../../global/layouts/MainContainer/SidebarButton/SidebarList';
import { WaitingModal } from '../../../global/layouts/PositiveModal/PositiveModal';
import LocationSlide from '../common/LocationSlide';
import NameSlide from '../common/NameSlide';

import CertificationSlide from './CertificationSlide';
import DemographicSlide from './DemographicSlide';
import EducationSlide from './EducationSlide';
import ExperienceSlide from './ExperienceSlide';
import IdealRoleSlide from './IdealRoleSlide';
import PortfolioSlide from './PortfolioSlide';
import QuestionSlide from './QuestionSlide';
import ResumeSlide from './ResumeSlide';
import ShareSlide from './ShareSlide';
import SkillsSlide from './SkillsSlide';

import type { User } from '../../../../types/user';

const routes = {
    name: { prev: 'demographics', next: 'location' },
    location: { prev: 'name', next: 'idealrole' },
    idealRole: { prev: 'location', next: 'resume' },
    resume: { prev: 'idealRole', next: 'experience' },
    experience: { prev: 'resume', next: 'education' },
    education: { prev: 'experience', next: 'certification' },
    certification: { prev: 'education', next: 'skills' },
    skills: { prev: 'certification', next: 'portfolio' },
    portfolio: { prev: 'skills', next: 'questions' },
    interestedQuestions: { prev: 'portfolio', next: 'demographics' },
    demographics: { prev: 'questions', next: 'name' }
} as unknown as {
    [key in keyof User | 'resume']: { prev: string; next: string };
};

export default function CandidateProfile() {
    const location = useLocation();
    const [redirect, setRedirect] = useState<string>('');
    const [resume, setResume] = useState<string>('');
    const { path } = useRouteMatch();
    const { user, setUser, updateUser } = useContext(UserContext);
    const { auth } = useContext(FirebaseContext);

    const [resumeUploaded, setResumeUploaded] = useState(false);
    const [resumeUploading, setResumeUploading] = useState(false);
    const [waitlistAccepted, setWaitlistAccepted] = useState(false);

    const defaultSideBarButtons = useDefaultSidebarButtons(user.userID);
    const scrollButtons = useCandidateProfileSidebarButtons(user.userID);

    const flowType = useMemo(
        () => (location.pathname.includes('/edit/') ? 'edit' : 'create'),
        [location.pathname]
    );

    const termsAccepted = (value: User['location']) =>
        user.location.termsAndConditions === false &&
        value.termsAndConditions === true;

    const update = (key: keyof User, value: User[keyof User]) => {
        const termsUpdated =
            key === 'location' && termsAccepted(value as User['location']);
        setUser({ ...user, [key]: value });
        if (flowType === 'edit') {
            updateUser({ [key]: value });
            if (termsUpdated) {
                setRedirect(
                    `/secure/${user.userID}/${user.levylRole}/dashboard`
                );
            }
        } else {
            setRedirect(
                `/${flowType}/${user.userID}/${user.levylRole}/${routes[key].next}`
            );
        }
    };

    const updateResumePath = (value: string) => {
        setResume(value);
        if (flowType !== 'edit') {
            setRedirect(
                `/${flowType}/${user.userID}/${user.levylRole}/${routes.resume.next}`
            );
        }
    };

    const submit = (updatedData: Partial<User>) => {
        updateUser(updatedData).then(() => {
            if (flowType === 'create') {
                setRedirect(`/create/${user.userID}/${user.levylRole}/share`);
            }
        });
    };

    const onClose = () =>
        updateUser().then(() => {
            setRedirect(`/secure/${user.userID}/${user.levylRole}/dashboard`);
        });

    useEffect(() => {
        if (redirect) {
            setRedirect('');
        }
    }, [redirect]);

    useEffect(() => {
        checkWaitlist(user.email).then((response) => {
            if (response === 'accepted') {
                setWaitlistAccepted(true);
            }
        });
    }, [user.email]);

    useEffect(() => {
        if (resume && user.userID) {
            setResumeUploading(true);
            parseResume(user.userID).then((response: { parse_id: string }) => {
                const intervalID = setInterval(() => {
                    getParsedResumeResult(response.parse_id).then(
                        (parseResponse: {
                            status: 'succeeded' | 'processing' | 'failed';
                            data: Partial<User>;
                        }) => {
                            if (parseResponse.status === 'succeeded') {
                                setResumeUploaded(true);
                                setResumeUploading(false);
                                updateUser(parseResponse.data);
                                clearInterval(intervalID);
                                setRedirect(
                                    `/${flowType}/${user.userID}/candidate/experience`
                                );
                            }
                        }
                    );
                }, 8000);
            });
        }
    }, [resume, user.userID, updateUser, flowType]);

    useEffect(() => {
        if (location.pathname.includes('candidate')) {
            updateDataLayer({
                role: 'candidate',
                user,
                resumeUploaded
            });
        }
    }, [location.pathname, user, resumeUploaded]);

    const CandidateProfileRoutes = () => (
        <Switch>
            <Route path={`${path}/name`}>
                <NameSlide
                    profile={user.name}
                    updateProfile={(value) => update('name', value)}
                    userRole={'candidate'}
                    onClose={onClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/location`}>
                <LocationSlide
                    profile={user.location}
                    updateProfile={(value) => update('location', value)}
                    userRole={'candidate'}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/name`}
                    onClose={onClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/idealrole`}>
                <IdealRoleSlide
                    profile={user.idealRole}
                    updateProfile={(value) => update('idealRole', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/location`}
                    onClose={onClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/resume`}>
                <ResumeSlide
                    profile={resume}
                    updateProfile={(value: string) => updateResumePath(value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/idealrole`}
                    onClose={onClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/experience`}>
                <ExperienceSlide
                    profile={user.experience}
                    updateProfile={(value) => update('experience', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/resume`}
                    onClose={onClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/education`}>
                <EducationSlide
                    profile={user.education}
                    updateProfile={(value) => update('education', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/experience`}
                    onClose={onClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/certification`}>
                <CertificationSlide
                    profile={user.certification}
                    updateProfile={(value) => update('certification', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/education`}
                    onClose={onClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/skills`}>
                <SkillsSlide
                    profile={user.skills}
                    updateProfile={(value) => update('skills', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/certification`}
                    onClose={onClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/portfolio`}>
                <PortfolioSlide
                    profile={user.portfolio}
                    updateProfile={(value) => update('portfolio', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/skills`}
                    onClose={onClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/questions`}>
                <QuestionSlide
                    profile={user.interestedQuestions.map(
                        (question) => question.question
                    )}
                    updateProfile={(value) =>
                        update(
                            'interestedQuestions',
                            value.map((question, index) => ({
                                question,
                                userID: user.id,
                                id: user.interestedQuestions[index].id,
                                numberResponse: false,
                                systemGeneratedID: ''
                            }))
                        )
                    }
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/portfolio`}
                    onClose={onClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/demographics`}>
                <DemographicSlide
                    profile={user.demographics}
                    updateProfile={(value) => update('demographics', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/questions`}
                    submitProfile={submit}
                    onClose={onClose}
                    isEdit={flowType === 'edit'}
                />
            </Route>
            <Route path={`${path}/share`}>
                <ShareSlide
                    buttonText={
                        waitlistAccepted
                            ? 'Go to Dashboard'
                            : 'Return to Homepage'
                    }
                    buttonLink={
                        waitlistAccepted
                            ? `/secure/${user.userID}/${user.levylRole}/dashboard`
                            : '/public/career-seekers'
                    }
                />
            </Route>
        </Switch>
    );

    return (
        <Fragment>
            {flowType === 'edit' ? (
                <MainContainer
                    sideBarButtons={defaultSideBarButtons}
                    firstName={user.name.firstName}
                    photoURL={user.name.imageURL}
                    signOut={() => {
                        auth?.signOut();
                    }}
                    scrollButtons={scrollButtons}
                >
                    <CandidateProfileRoutes />
                </MainContainer>
            ) : (
                <MainContainer public fullWidth>
                    <CandidateProfileRoutes />
                </MainContainer>
            )}
            {resumeUploading && (
                <WaitingModal
                    open={resumeUploading}
                    title={
                        "We're processing your resume, to make life easier for you."
                    }
                    subtitle={
                        'Give us a moment while we jot down all of the wonderful things you have done.'
                    }
                    image={waitImage}
                />
            )}
            {redirect && <Redirect to={redirect} />}
        </Fragment>
    );
}
