import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { CreateInterviewDetails } from '../../../types/interview';
import { CandidateSearch, SimpleJob } from '../../../types/job';

import CompleteSlide from './CompleteSlide';
import DescriptionSlide from './DescriptionSlide';
import DetailsSlide from './DetailsSlide';
import IntroSlide from './IntroSlide';
import SalarySlide from './SalarySlide';
import ScheduleSlide from './ScheduleSlide';

export const routes = {
    intro: { next: 'details', prev: 'complete' },
    details: { next: 'salary', prev: 'intro' },
    salary: { next: 'description', prev: 'details' },
    description: { next: 'schedule', prev: 'salary' },
    schedule: { next: 'complete', prev: 'description' },
    complete: { next: 'intro', prev: 'schedule' }
};

type JobPostRoutesProps = {
    path: string;
    job: SimpleJob;
    setJobAndRedirect: (job: SimpleJob, page: keyof typeof routes) => void;
    candidateCount: number;
    setCandidateCount: (count: number) => void;
    query: CandidateSearch;
    setQuery: (query: CandidateSearch) => void;
    submitJob: (jobDetails: SimpleJob) => void;
    submitInterview: (
        interviewDetails: CreateInterviewDetails['details']
    ) => void;
    getPageURL: (page: keyof typeof routes, reverse?: boolean) => string;
    onClose: () => Promise<void>;
    isEdit: boolean;
};

const JobPostRoutes = ({
    path,
    job,
    setJobAndRedirect,
    candidateCount,
    setCandidateCount,
    query,
    setQuery,
    submitJob,
    submitInterview,
    getPageURL,
    onClose,
    isEdit
}: JobPostRoutesProps) => (
    <Switch>
        <Route path={`${path}/intro`}>
            <IntroSlide nextURL={getPageURL('details')} onClose={onClose} />
        </Route>
        <Route path={`${path}/details`}>
            <DetailsSlide
                job={job}
                setJob={(newJob) => setJobAndRedirect(newJob, 'salary')}
                candidateCount={candidateCount}
                setCandidateCount={setCandidateCount}
                query={query}
                setQuery={setQuery}
                prevURL={getPageURL('intro', true)}
                onClose={onClose}
                isEdit={isEdit}
            />
        </Route>
        <Route path={`${path}/salary`}>
            <SalarySlide
                job={job}
                setJob={(newJob) => setJobAndRedirect(newJob, 'description')}
                candidateCount={candidateCount}
                setCandidateCount={setCandidateCount}
                query={query}
                setQuery={setQuery}
                prevURL={getPageURL('details', true)}
                onClose={onClose}
                isEdit={isEdit}
            />
        </Route>
        <Route path={`${path}/description`}>
            <DescriptionSlide
                job={job}
                setJob={submitJob}
                candidateCount={candidateCount}
                prevURL={getPageURL('salary', true)}
                onClose={onClose}
                isEdit={isEdit}
            />
        </Route>
        <Route path={`${path}/schedule`}>
            <ScheduleSlide
                job={job}
                submitInterview={submitInterview}
                candidateCount={candidateCount}
                prevURL={getPageURL('description', true)}
                onClose={onClose}
                isEdit={isEdit}
            />
        </Route>
        <Route path={`${path}/complete`}>
            <CompleteSlide onClose={onClose} />
        </Route>
    </Switch>
);

export default JobPostRoutes;
