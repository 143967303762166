const content = {
    company: {
        title: 'Create your business page',
        profileComplete: 'Congratulations, your profile is on!',
        invite: 'You can get started inviting other team members to your Levyl page.',
        name: {
            label: 'Business name'
        },
        website: {
            label: 'Website'
        },
        industry: {
            label: 'Industry'
        },
        companySize: {
            label: 'Company Size',
            options: [
                {
                    label: '0-50',
                    value: '0-50'
                },
                {
                    label: '51-200',
                    value: '51-200'
                },
                {
                    label: '201-500',
                    value: '201-500'
                },
                {
                    label: '500+',
                    value: '500+'
                }
            ]
        },
        location: {
            label: 'Location'
        },
        imageURL: {
            label: ' your logo'
        },
        description: {
            label: 'Description'
        },
        termsAndConditions: {
            label: 'I verify that I’m an authorized representative of this organization and am able to make hiring decisions on behalf of this company.'
        },
        plan: {
            title: "You've selected the ",
            label: 'All plans include unlimited users.',
            options: [
                { label: 'Basic', value: 'basic' },
                { label: 'Plus', value: 'plus' },
                { label: 'Enterprise', value: 'enterprise' }
            ],
            basic: {
                price: '$499',
                details: [
                    'Hire up to 5 roles per year',
                    '$99.80 / year per role',
                    '$109.78 for additional roles'
                ]
            },
            plus: {
                price: '$999',
                details: [
                    'Hire up to 12 roles per year',
                    '$83.25 / year per role',
                    '$94.63 for additional roles'
                ]
            },
            enterprise: {
                price: 'Contact Us for Pricing',
                details: [
                    'Reach out to us at contact@levyl.app to discuss a custom plan to fit your needs'
                ]
            }
        },
        employee: {
            email: {
                label: 'Email'
            }
        }
    }
};

export default content;
