import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextButton } from '../../../global/components/Button/Button';
import TextInput from '../../../global/components/TextField/TextField';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import { FormSection, NextSteps, StyledButton } from '../styles';

import content from './content';

import type { UserProfile } from '../userProfileTypes';
import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup.object().shape({
    portfolio: yup.array().of(
        yup.object().shape({
            title: yup.string(),
            url: yup.string().url()
        })
    )
});

export default function PortfolioSlide(props: {
    profile: UserProfile['portfolio'];
    updateProfile: (value: UserProfile['portfolio']) => void;
    prevURL: string;
    onClose: () => Promise<void>;
    isEdit?: boolean;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: { portfolio: props.profile }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'portfolio'
    });

    const onSubmit = (data: { portfolio: UserProfile['portfolio'] }) => {
        props.updateProfile(data.portfolio);
    };

    return (
        <ColumnCard
            closeScreen
            step={3}
            screenType={'candidate'}
            onClose={props.onClose}
            id={'portfolioSlide'}
            wide
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {commonContent.common.title}
                </Typography>
                <Typography>
                    {
                        'Tell us about your projects and share your work and other links that can tell us more about yourself.'
                    }
                </Typography>
                {fields.map((field, index) => (
                    <div key={field.id} style={{ display: 'flex' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid #C2C2C2',
                                padding: '1rem',
                                borderRadius: '0.5rem',
                                marginTop: '1rem',
                                flexGrow: 1
                            }}
                        >
                            <TextInput
                                id={`portfolio.${index}.title`}
                                label={content.portfolio.title.label}
                                color='primary'
                                error={!!errors.portfolio?.[index]?.title}
                                errorText={
                                    errors.portfolio?.[index]?.title?.message
                                }
                                placeholder={
                                    content.portfolio.title.placeholder
                                }
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                            />
                            <TextInput
                                id={`portfolio.${index}.url`}
                                label={content.portfolio.url.label}
                                color='primary'
                                error={!!errors.portfolio?.[index]?.url}
                                errorText={
                                    errors.portfolio?.[index]?.url?.message
                                }
                                placeholder={content.portfolio.url.placeholder}
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                            />
                        </div>
                        {fields.length > 1 && (
                            <IconButton
                                id={`portfolio.${index}.remove`}
                                color='primary'
                                aria-label={content.portfolio.remove}
                                component='label'
                                sx={{ ml: 1 }}
                                onClick={() => remove(index)}
                            >
                                <HighlightOffIcon />
                            </IconButton>
                        )}
                    </div>
                ))}
                <div style={{ marginTop: '1rem', paddingLeft: '1rem' }}>
                    <TextButton
                        id={'addPortfolio'}
                        startIcon={<AddCircleOutlineIcon />}
                        sx={{ fontWeight: 400, mt: 2 }}
                        onClick={() =>
                            append({
                                title: '',
                                url: ''
                            })
                        }
                    >
                        {content.portfolio.add}
                    </TextButton>
                </div>
                <NextSteps>
                    {!props.isEdit && (
                        <TextButton to={props.prevURL}>
                            {commonContent.common.back}
                        </TextButton>
                    )}
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id='submit'
                    >
                        {props.isEdit ? 'Save' : commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
