// Utility for interacting with localStorage
const localStorageUtil = {
    /**
     * Save a value to localStorage
     * @param {string} key - The key to store the value under.
     * @param {*} value - The value to store. Non-string values will be stringified.
     */
    setItem: (key: string, value: any) => {
        try {
            const valueToStore =
                typeof value === 'string' ? value : JSON.stringify(value);
            localStorage.setItem(key, valueToStore);
        } catch (error) {
            console.error('Error setting item in localStorage:', error);
        }
    },

    /**
     * Get a value from localStorage
     * @param {string} key - The key to retrieve the value for.
     * @returns {*} - The parsed value if it was JSON, or the raw string value.
     */
    getItem: (key: string) => {
        try {
            const storedValue = localStorage.getItem(key);
            if (!storedValue) return null;
            try {
                return JSON.parse(storedValue);
            } catch {
                return storedValue; // Return raw value if not valid JSON
            }
        } catch (error) {
            console.error('Error getting item from localStorage:', error);
            return null;
        }
    },

    /**
     * Remove a value from localStorage
     * @param {string} key - The key to remove.
     */
    removeItem: (key: string) => {
        try {
            if (localStorageUtil.getItem(key)) {
                localStorage.removeItem(key);
            }
        } catch (error) {
            console.error('Error removing item from localStorage:', error);
        }
    },

    /**
     * Clear all values from localStorage
     */
    clear: () => {
        try {
            localStorage.clear();
        } catch (error) {
            console.error('Error clearing localStorage:', error);
        }
    }
};

export default localStorageUtil;
