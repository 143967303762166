import { Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { getUserJobs } from '../../../../services/jobs';
import thumbsUp from '../../../../static/icons/thumbs-up.svg';
import { UserContext } from '../../../context/UserContext';
import { BaseContainer } from '../../../global/Core';

import JobList from './JobList';

import type { Job } from '../../../../types/job';

export default function CandidateDashboard() {
    const [jobs, setJobs] = useState<Job[]>([]);
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (!user.id) return;
        getUserJobs(user.id).then((jobList: Job[]) => {
            const sortedJobs = jobList.sort((a, b) => {
                if (a.saved && !b.saved) return -1;
                if (!a.saved && b.saved) return 1;
                return 0;
            });
            setJobs(sortedJobs);
        });
    }, [user.id]);

    return (
        <BaseContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant={'h1'} color={'text.secondary'}>
                    {user.name.firstName
                        ? `Hi, ${user.name.firstName}!`
                        : 'Welcome to Levyl!'}
                </Typography>
                <img src={thumbsUp} style={{ marginLeft: '1.5rem' }} />
            </div>
            <JobList jobs={jobs} />
        </BaseContainer>
    );
}
