import ArticleIcon from '@mui/icons-material/Article';
import BadgeIcon from '@mui/icons-material/Badge';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EventIcon from '@mui/icons-material/Event';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import InfoIcon from '@mui/icons-material/Info';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import TelegramIcon from '@mui/icons-material/Telegram';
import WorkIcon from '@mui/icons-material/Work';
import React from 'react';
import { useLocation } from 'react-router-dom';

import type { SideBarButtons } from './SidebarButton';

export const iconMapping = {
    Dashboard: <PersonIcon />,
    'My Interviews': <WorkIcon />,
    'My Network': <SchoolIcon />,
    Messaging: <TelegramIcon />,
    'Job Offers': <TelegramIcon />,
    Name: <BadgeIcon />,
    Location: <LocationOnIcon />,
    'Ideal Role': <LightbulbCircleIcon />,
    Resume: <ArticleIcon />,
    Experience: <WorkIcon />,
    Education: <SchoolIcon />,
    Certification: <CardMembershipIcon />,
    Skills: <EditIcon />,
    Portfolio: <FolderCopyIcon />,
    Questions: <QuizIcon />,
    Demographic: <PeopleIcon />,
    Info: <InfoIcon />,
    Description: <DescriptionIcon />,
    Plans: <PriceChangeIcon />,
    Salary: <LocalAtmIcon />,
    Details: <EditNoteIcon />,
    Schedule: <EventIcon />,
    'Career Seekers': <PersonIcon />,
    Employers: <WorkIcon />
};

export const useDefaultSidebarButtons = (userID: string): SideBarButtons => {
    const { pathname } = useLocation();
    return [
        {
            text: 'Dashboard',
            to: `/secure/${userID}/dashboard`,
            active: pathname.includes('dashboard')
        },
        {
            text: 'My Interviews',
            to: `/secure/${userID}/upcominginterviews`,
            active: pathname.includes('upcominginterviews')
        },
        {
            text: 'My Network',
            to: `/secure/${userID}/network`,
            active: pathname.includes('network')
        },
        {
            text: 'Messaging',
            to: `/secure/${userID}/messaging`,
            active: pathname.includes('messaging')
        }
    ];
};

export const useCandidateProfileSidebarButtons = (
    userID: string
): SideBarButtons => {
    const { pathname } = useLocation();
    return [
        { text: 'Dashboard', to: `/secure/${userID}/dashboard`, active: false },
        {
            text: 'Name',
            to: `/edit/${userID}/candidate/name`,
            active: pathname.includes('name')
        },
        {
            text: 'Location',
            to: `/edit/${userID}/candidate/location`,
            active: pathname.includes('location')
        },
        {
            text: 'Ideal Role',
            to: `/edit/${userID}/candidate/idealrole`,
            active: pathname.includes('idealrole')
        },
        {
            text: 'Resume',
            to: `/edit/${userID}/candidate/resume`,
            active: pathname.includes('resume')
        },
        {
            text: 'Experience',
            to: `/edit/${userID}/candidate/experience`,
            active: pathname.includes('experience')
        },
        {
            text: 'Education',
            to: `/edit/${userID}/candidate/education`,
            active: pathname.includes('education')
        },
        {
            text: 'Certification',
            to: `/edit/${userID}/candidate/certification`,
            active: pathname.includes('certification')
        },
        {
            text: 'Skills',
            to: `/edit/${userID}/candidate/skills`,
            active: pathname.includes('skills')
        },
        {
            text: 'Portfolio',
            to: `/edit/${userID}/candidate/portfolio`,
            active: pathname.includes('portfolio')
        },
        {
            text: 'Questions',
            to: `/edit/${userID}/candidate/questions`,
            active: pathname.includes('questions')
        },
        {
            text: 'Demographic',
            to: `/edit/${userID}/candidate/demographics`,
            active: pathname.includes('demographics')
        }
    ];
};

export const useBusinessProfileSidebarButtons = (
    userID: string,
    companyID?: number
): SideBarButtons => {
    const { pathname } = useLocation();
    if (!companyID) {
        return [
            {
                text: 'Dashboard',
                to: `/secure/${userID}/dashboard`,
                active: false
            },
            {
                text: 'Name',
                to: `/edit/${userID}/business/name`,
                active: pathname.includes('name')
            },
            {
                text: 'Location',
                to: `/edit/${userID}/business/location`,
                active: pathname.includes('location')
            }
        ];
    }
    return [
        {
            text: 'Dashboard',
            to: `/secure/${userID}/dashboard`,
            active: false
        },
        {
            text: 'Info',
            to: `/edit/${userID}/business/${companyID}/companypage`,
            active: pathname.includes('companypage')
        },
        {
            text: 'Description',
            to: `/edit/${userID}/business/${companyID}/companydescription`,
            active: pathname.includes('companydescription')
        },
        {
            text: 'Plans',
            to: `/edit/${userID}/business/${companyID}/plans`,
            active: pathname.includes('plans')
        }
    ];
};

export const useCreateJobSidebarButtons = (
    userID: string,
    companyID: number,
    jobID: number
): SideBarButtons => {
    const { pathname } = useLocation();
    return [
        { text: 'Dashboard', to: `/secure/${userID}/dashboard`, active: false },
        {
            text: 'Details',
            to: `/edit/${userID}/job/${companyID}/${jobID}/details`,
            active: pathname.includes('details')
        },
        {
            text: 'Salary',
            to: `/edit/${userID}/job/${companyID}/${jobID}/salary`,
            active: pathname.includes('salary')
        },
        {
            text: 'Description',
            to: `/edit/${userID}/job/${companyID}/${jobID}/description`,
            active: pathname.includes('description')
        },
        {
            text: 'Schedule',
            to: `/edit/${userID}/job/${companyID}/${jobID}/schedule`,
            active: pathname.includes('schedule')
        }
    ];
};
