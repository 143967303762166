import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import React from 'react';

import type { Control, FieldValues } from 'react-hook-form';

const StyledSection = styled('div')`
    width: 100%;
    display: flex;
    gap: 0.5rem;
`;

const StyledChipStack = styled(Stack)`
    width: 100%;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
`;

const ChipList = (props: {
    name: string;
    values: string[];
    addValue: () => void;
    removeValue: (index: number) => void;
    control: Control<FieldValues>;
    children: React.ReactNode;
}) => (
    <StyledSection
        id={`chip-list-${props.name}`}
        style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem'
        }}
    >
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {props.children}
            <IconButton
                id={`add-${props.name}`}
                aria-label={`add ${props.name}`}
                size='large'
                onClick={props.addValue}
                sx={{ p: 0, pl: 1 }}
            >
                <AddIcon fontSize='inherit' />
            </IconButton>
        </div>
        <StyledChipStack>
            {props.values.map((value, index) => (
                <Chip
                    id={`chip-${value}`}
                    label={value}
                    key={value}
                    onDelete={() => {
                        props.removeValue(index);
                    }}
                    color='primary'
                    variant='outlined'
                />
            ))}
        </StyledChipStack>
    </StyledSection>
);

export default ChipList;
