import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Company } from '../../../../types/company';
import { TextButton } from '../../../global/components/Button/Button';
import Select from '../../../global/components/SelectField';
import TextInput from '../../../global/components/TextField/TextField';
import Upload from '../../../global/components/UploadFile';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import {
    FormSection,
    NextSteps,
    ProfilePicture,
    StyledButton
} from '../styles';

import content from './content';

import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup
    .object()
    .shape({
        name: yup.string().required(commonContent.common.errors.required),
        logoUrl: yup.string(),
        websiteUrl: yup
            .string()
            .url()
            .required(commonContent.common.errors.required),
        industry: yup.string().required(commonContent.common.errors.required),
        location: yup.string().required(commonContent.common.errors.required),
        size: yup
            .string()
            .oneOf(['0-50', '51-200', '201-500', '500+'])
            .required(commonContent.common.errors.required)
    })
    .required();

export default function BusinessInfo(props: {
    company: Company;
    setCompanyProfile: (value: Partial<Company>) => void;
    prevURL: string;
    onClose: () => Promise<void>;
    isEdit?: boolean;
}) {
    const [imageURL, setImageURL] = useState(props.company.logoURL);
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            name: props.company.name,
            logoUrl: props.company.logoURL,
            websiteUrl: props.company.websiteURL,
            industry: props.company.industry,
            location: props.company.location,
            size: props.company.size
        }
    });
    const { uid } = useParams<{ uid: string }>();

    const onSubmit = (data: {
        name: string;
        websiteUrl: string;
        industry: string;
        location: string;
        size: string;
    }) => {
        props.setCompanyProfile({
            name: data.name,
            logoURL: imageURL,
            websiteURL: data.websiteUrl,
            industry: data.industry,
            location: data.location,
            size: data.size
        });
    };

    return (
        <ColumnCard
            closeScreen
            step={2}
            screenType={'business'}
            onClose={props.onClose}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {content.company.title}
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        marginTop: '1rem',
                        justifyContent: 'space-around'
                    }}
                >
                    <ProfilePicture src={imageURL} sx={{ mr: 1 }} />
                    <Upload
                        userId={uid}
                        value={imageURL}
                        setValue={setImageURL}
                        id={'companyImage'}
                        label={content.company.imageURL.label}
                    />
                </div>
                <TextInput
                    id='name'
                    label={content.company.name.label}
                    color='primary'
                    error={!!errors.name}
                    errorText={errors.name?.message}
                    placeholder={''}
                    control={control as unknown as Control<FieldValues>}
                />
                <TextInput
                    id='websiteUrl'
                    label={content.company.website.label}
                    color='primary'
                    error={!!errors.websiteUrl}
                    errorText={errors.websiteUrl?.message}
                    placeholder={''}
                    control={control as unknown as Control<FieldValues>}
                />
                <TextInput
                    id='industry'
                    label={content.company.industry.label}
                    color='primary'
                    error={!!errors.industry}
                    errorText={errors.industry?.message}
                    placeholder={''}
                    control={control as unknown as Control<FieldValues>}
                />
                <Select
                    id='size'
                    label={content.company.companySize.label}
                    color='primary'
                    error={!!errors.size}
                    errorText={errors.size?.message}
                    control={control as unknown as Control<FieldValues>}
                    options={content.company.companySize.options}
                />
                <TextInput
                    id='location'
                    label={content.company.location.label}
                    color='primary'
                    error={!!errors.location}
                    errorText={errors.location?.message}
                    placeholder={''}
                    control={control as unknown as Control<FieldValues>}
                />
                <NextSteps>
                    {!props.isEdit && (
                        <TextButton to={props.prevURL}>
                            {commonContent.common.back}
                        </TextButton>
                    )}
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id={'submit'}
                    >
                        {props.isEdit ? 'Save' : commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
