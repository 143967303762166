import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getEligibleUserCount } from '../../../services/user';
import candidateCountImg from '../../../static/illustrations/candidate-count.svg';
import { CandidateSearch, SimpleJob } from '../../../types/job';
import { TextButton } from '../../global/components/Button/Button';
import RadioButton from '../../global/components/RadioButton';
import Select from '../../global/components/SelectField';
import TextInput from '../../global/components/TextField/TextField';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import { FormSection, NextSteps, StyledButton } from '../Profile/styles';

import content from './content';
import { getMaxExperience, getMinExperience } from './CreateJob.utils';

import type { UserEducation, WorkingPreference } from '../../../types/enum';
import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup
    .object()
    .shape({
        title: yup.string().required(),
        workExperience: yup.string().oneOf(['0-2', '2-4', '4-9', '10']),
        educationType: yup
            .string()
            .oneOf([
                'none',
                'high_school_diploma',
                'college_diploma',
                'bachelors_degree',
                'masters_degree',
                'doctorate_degree'
            ]),
        workEnvironment: yup.string().oneOf(['remote', 'hybrid', 'office'])
    })
    .required();

export default function DetailsSlide(props: {
    job: SimpleJob;
    setJob: (job: SimpleJob) => void;
    candidateCount: number;
    setCandidateCount: (count: number) => void;
    query: CandidateSearch;
    setQuery: (query: CandidateSearch) => void;
    prevURL: string;
    onClose: () => Promise<void>;
    isEdit: boolean;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control,
        watch
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            title: props.job.title,
            workExperience:
                props.job.minExperience && props.job.maxExperience
                    ? `${props.job.minExperience}-${props.job.maxExperience}`
                    : '',
            educationType: props.job.educationType,
            workPreference: props.job.workPreference
        }
    });

    const onSubmit = (data: {
        title: string;
        workExperience: string;
        educationType: UserEducation;
        workPreference: WorkingPreference;
    }) => {
        props.setJob({
            ...props.job,
            title: data.title,
            minExperience: getMinExperience(data.workExperience),
            maxExperience: getMaxExperience(data.workExperience),
            educationType: data.educationType,
            workPreference: data.workPreference
        });
    };

    const { query, setQuery, setCandidateCount } = props;

    useEffect(() => {
        const subscription = watch((value) => {
            const { title, workExperience, educationType, workPreference } =
                value;
            setQuery({
                ...query,
                title,
                min_experience: getMinExperience(workExperience),
                max_experience: getMaxExperience(workExperience),
                min_education_level: educationType,
                work_environment: workPreference
            });
            if (workExperience !== '') {
                getEligibleUserCount({
                    ...query,
                    min_experience: getMinExperience(workExperience),
                    max_experience: getMaxExperience(workExperience)
                }).then((count: number) => {
                    setCandidateCount(count);
                });
            }
            if (educationType) {
                getEligibleUserCount({
                    ...query,
                    min_education_level: educationType
                }).then((count: number) => {
                    setCandidateCount(count);
                });
            }
            if (workPreference) {
                getEligibleUserCount({
                    ...query,
                    work_environment: workPreference
                }).then((count: number) => {
                    setCandidateCount(count);
                });
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, query, setQuery, setCandidateCount]);

    return (
        <ColumnCard
            closeScreen
            step={1}
            screenType={'job'}
            image={{
                src: candidateCountImg,
                alt: 'total candidates'
            }}
            onClose={props.onClose}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {'Create Job Posting'}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                    {'Tell us about what you want for your team.'}
                </Typography>
                <TextInput
                    id='title'
                    label={content.title.title.label}
                    color='primary'
                    error={!!errors.title}
                    errorText={content.common.errors.required}
                    placeholder={content.title.title.placeholder}
                    control={control as unknown as Control<FieldValues>}
                    onBlur={() => {
                        getEligibleUserCount(props.query).then(
                            (count: number) => {
                                props.setCandidateCount(count);
                            }
                        );
                    }}
                    type='string'
                />
                <Select
                    id='workExperience'
                    label={content.education.workExperience.label}
                    color='primary'
                    error={!!errors.workExperience}
                    errorText={content.common.errors.required}
                    control={control as unknown as Control<FieldValues>}
                    options={content.education.workExperience.options}
                />
                <Select
                    id='educationType'
                    label={content.education.educationLevel.label}
                    color='primary'
                    error={!!errors.educationType}
                    errorText={content.common.errors.required}
                    control={control as unknown as Control<FieldValues>}
                    options={content.education.educationLevel.options}
                />
                <RadioButton
                    id='workPreference'
                    title={content.environment.label}
                    color='primary'
                    error={!!errors.workPreference}
                    errorText={content.common.errors.required}
                    control={control as unknown as Control<FieldValues>}
                    labels={content.environment.options}
                />
                <NextSteps>
                    {!props.isEdit && (
                        <TextButton to={props.prevURL}>
                            {content.common.back}
                        </TextButton>
                    )}
                    <StyledButton variant='outlined' sx={{ m: 2 }} submit>
                        {props.isEdit ? 'Save' : content.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
