import firebase from 'firebase/compat/app';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CreateIntroSlide from './CreateIntroSlide';
import Login from './Login';

export default function LoginRoutes(props: {
    user: firebase.User | null | undefined;
    waitlistAccepted?: boolean;
}) {
    return (
        <Switch>
            <Route exact path={['/signup/select']}>
                <CreateIntroSlide />
            </Route>
            <Route exact path={['/signup/user', '/signup/user/:uid']}>
                {props.user && (
                    <Redirect to={`/create/${props.user.uid}/candidate/name`} />
                )}
                {props.user === null && <Login />}
            </Route>
            <Route exact path={['/signup/business', '/signup/business/:uid']}>
                {props.user && (
                    <Redirect to={`/create/${props.user.uid}/business/name`} />
                )}
                {props.user === null && <Login />}
            </Route>
            <Route exact path={['/', '/login', '/resetpassword']}>
                {props.user && props.waitlistAccepted === true && (
                    <Redirect to={`/secure/${props.user.uid}/dashboard`} />
                )}
                {props.user && props.waitlistAccepted === false && (
                    <Redirect to={`/edit/${props.user.uid}/candidate/name`} />
                )}
                {(props.user === null ||
                    props.waitlistAccepted === undefined) && <Login />}
            </Route>
        </Switch>
    );
}
