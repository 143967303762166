import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getEligibleUserCount } from '../../../services/user';
import candidateCountImg from '../../../static/illustrations/candidate-count.svg';
import { CandidateSearch, SimpleJob } from '../../../types/job';
import { TextButton } from '../../global/components/Button/Button';
import Checkbox from '../../global/components/Checkbox';
import ChipList from '../../global/components/ChipList';
import TextInput from '../../global/components/TextField/TextField';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import { FormSection, NextSteps, StyledButton } from '../Profile/styles';

import content from './content';

import type { Control, FieldValues } from 'react-hook-form';

const StyledTextField = styled(TextInput)`
    flex-grow: 1;
`;

const formSchema = yup
    .object()
    .shape({
        minSalary: yup.number().positive().required(),
        maxSalary: yup.number().positive().required(),
        showSalary: yup.bool(),
        benefits: yup.array().of(yup.object().shape({ benefit: yup.string() })),
        currentBenefit: yup.string()
    })
    .required();

export default function SalarySlide(props: {
    job: SimpleJob;
    setJob: (job: SimpleJob) => void;
    candidateCount: number;
    setCandidateCount: (count: number) => void;
    query: CandidateSearch;
    setQuery: (query: CandidateSearch) => void;
    prevURL: string;
    onClose: () => Promise<void>;
    isEdit: boolean;
}) {
    const [showHideSalary, setHideSalary] = useState<boolean | undefined>(
        false
    );
    const {
        handleSubmit,
        setValue,
        formState: { errors },
        control,
        watch
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            minSalary: props.job.minSalary,
            maxSalary: props.job.maxSalary,
            hideSalary: false,
            benefits: props.job.benefits.map((benefit) => ({ benefit })),
            currentBenefit: ''
        }
    });

    const currentBenefit = watch('currentBenefit');

    const {
        fields: benefitsFields,
        append: benefitsAppend,
        remove: benefitsRemove
    } = useFieldArray({
        control,
        name: 'benefits'
    });

    const onSubmit = (data: {
        minSalary: number;
        maxSalary: number;
        hideSalary: boolean;
        benefits: Array<{ benefit: string }>;
    }) => {
        props.setJob({
            ...props.job,
            minSalary: data.minSalary,
            maxSalary: data.maxSalary,
            hideSalary: data.hideSalary,
            benefits: data.benefits.map((benefit) => benefit.benefit)
        });
    };

    const { query, setQuery } = props;

    useEffect(() => {
        const subscription = watch((value) => {
            const { minSalary, hideSalary } = value;
            setHideSalary(hideSalary);
            setQuery({
                ...query,
                min_salary: minSalary
            });
        });
        return () => subscription.unsubscribe();
    }, [watch, query, setQuery]);

    return (
        <ColumnCard
            closeScreen
            step={2}
            screenType={'job'}
            image={{
                src: candidateCountImg,
                alt: 'total candidates'
            }}
            onClose={props.onClose}
        >
            <Typography
                variant='h2'
                component='h1'
                fontWeight='400'
                sx={{ mb: 2 }}
            >
                {'Create Job Posting'}
            </Typography>
            <Typography sx={{ mb: 1 }}>
                {'Tell us about the salary details and benefits.'}
            </Typography>
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                    id='minSalary'
                    label={content.salary.minimumSalary.label}
                    color='primary'
                    error={!!errors.minSalary}
                    errorText={content.common.errors.required}
                    placeholder={''}
                    control={control as unknown as Control<FieldValues>}
                    onBlur={() => {
                        getEligibleUserCount(props.query).then(
                            (count: number) => {
                                props.setCandidateCount(count);
                            }
                        );
                    }}
                    type='number'
                />
                <TextInput
                    id='maxSalary'
                    label={content.salary.maximumSalary.label}
                    color='primary'
                    error={!!errors.maxSalary}
                    errorText={content.common.errors.required}
                    placeholder={''}
                    control={control as unknown as Control<FieldValues>}
                    type='number'
                />
                <Checkbox
                    id='hideSalary'
                    label={content.salary.checkbox.label}
                    color='primary'
                    control={control as unknown as Control<FieldValues>}
                />
                {showHideSalary && (
                    <Typography
                        color='primary'
                        variant='body1'
                        fontWeight='400'
                        sx={{ ml: 4 }}
                    >
                        {content.salary.checkbox.description}
                    </Typography>
                )}
                <ChipList
                    name='benefits'
                    values={benefitsFields.map((field) => field.benefit)}
                    addValue={() => {
                        if (
                            currentBenefit &&
                            !benefitsFields
                                .map((field) => field.benefit.toLowerCase())
                                .includes(currentBenefit.toLowerCase())
                        ) {
                            benefitsAppend({ benefit: currentBenefit });
                            setValue('currentBenefit', '');
                        }
                    }}
                    removeValue={benefitsRemove}
                    control={control as unknown as Control<FieldValues>}
                >
                    <StyledTextField
                        id={'currentBenefit'}
                        color='primary'
                        variant='outlined'
                        label={'Enter Benefits'}
                        error={''}
                        control={control}
                    />
                </ChipList>
                <NextSteps>
                    {!props.isEdit && (
                        <TextButton to={props.prevURL}>
                            {content.common.back}
                        </TextButton>
                    )}
                    <StyledButton variant='outlined' sx={{ m: 2 }} submit>
                        {props.isEdit ? 'Save' : content.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
