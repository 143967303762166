import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

export const StyledCard = styled(Card)<{ isComponent: boolean }>(
    ({ isComponent }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 2rem;
    position: relative;
    ${!isComponent && 'flex: 1;'}
`
);

export const StyledContent = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
    }
}));

export const StyledCloseButtonContainer = styled('div')`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
`;

export const LeftSection = styled('div')<{ wide?: boolean }>(
    ({ theme, wide }) => ({
        width: wide ? '30rem' : '20rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    })
);

export const StyledImageContainer = styled('div')`
    max-width: 25rem;
    position: relative;
    text-align: center;
`;

export const StyledImage = styled('img')<{ hide?: boolean }>(
    ({ theme, hide }) => ({
        maxWidth: '100%',
        maxHeight: '100%',
        [theme.breakpoints.down('md')]: {
            marginTop: '2rem'
        },
        display: hide ? 'none' : 'inherit'
    })
);

export const StepsContainer = styled('div')`
    display: flex;
    justify-content: space-around;
    margin-bottom: 1.5rem;
`;

export const SingleStep = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const OverlayText = styled('div')`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 1rem;
`;
