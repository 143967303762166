import { styled } from '@mui/material/styles';

import Button from '../../global/components/Button/Button';
import Checkbox from '../../global/components/Checkbox';
import Select from '../../global/components/SelectField';
import TextInput from '../../global/components/TextField/TextField';

export const FormSection = styled('form')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

const ProfilePictureBase = styled('div')<{ src: string }>(
    ({ theme, src }) => `
    background-color: ${theme.palette.secondary.main};
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    background-image: url(${src});
`
);

export const ProfilePicture = styled(ProfilePictureBase)(() => ({
    height: '4rem',
    width: '4rem',
    flexShrink: 0
}));

export const NextSteps = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
`;

export const StyledButton = styled(Button)`
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const CheckboxWithSpacingTop = styled(Checkbox)`
    margin-top: 1rem;
`;

export const StyledSelect = styled(Select)`
    flex-grow: 1;
`;

export const StyledTextInput = styled(TextInput)`
    flex: 1;
`;
