const content = {
    idealRole: {
        desiredRoles: {
            label: 'What types or roles are you looking for?',
            options: [
                {
                    label: 'Product Manager',
                    value: 'product manager'
                },
                {
                    label: 'Software Engineer',
                    value: 'software engineer'
                },
                { label: 'Designer', value: 'designer' },
                { label: 'Other', value: 'other' }
            ],
            remove: 'Remove desired role',
            add: 'Add another job title'
        },
        desiredLocations: {
            label: 'Where do you prefer to work?',
            options: [
                {
                    label: 'Toronto',
                    value: 'toronto'
                },
                {
                    label: 'Montreal',
                    value: 'montreal'
                },
                { label: 'Vancouver', value: 'vancouver' }
            ],
            remove: 'Remove desired location',
            add: 'Add another location'
        },
        desiredSalary: {
            label: 'Your ideal annual salary',
            placeholder: '$80,000',
            currencySymbol: '$'
        },
        workingEnvironment: {
            label: 'What is your preferred working environment?',
            options: [
                { label: 'No Preference', value: 'no_preference' },
                { label: 'In-Office', value: 'office' },
                { label: 'Remote', value: 'remote' },
                { label: 'Hybrid', value: 'hybrid' }
            ]
        },
        errors: {
            salaryNumber: 'Salary must be a number',
            salaryMin: 'Minimum salary should be at least $30 000'
        }
    },
    resume: {
        resume: {
            title: 'Upload your resume',
            subtitle: 'Only PDF files. Max file size, 500KB.',
            label: 'resume'
        }
    },
    experience: {
        title: { label: 'Title', placeholder: 'Product Manager' },
        company: { label: 'Company', placeholder: 'Google' },
        startDate: { label: 'Start date', placeholder: 'YYYY-MM-DD' },
        endDate: { label: 'End date', placeholder: 'YYYY-MM-DD' },
        add: 'Additional experience',
        remove: 'Remove experience'
    },
    education: {
        title: { label: 'Title', placeholder: 'Bachelor of Computer Science' },
        institution: {
            label: 'Institution',
            placeholder: 'University of Toronto'
        },
        startDate: { label: 'Start date', placeholder: 'YYYY-MM-DD' },
        endDate: { label: 'End date', placeholder: 'YYYY-MM-DD' },
        add: 'Additional education',
        remove: 'Remove education'
    },
    certification: {
        title: {
            label: 'Title',
            placeholder: 'AWS Cloud Engineer Certificate'
        },
        institution: {
            label: 'Institution',
            placeholder: 'University of Toronto'
        },
        endDate: { label: 'End date', placeholder: 'YYYY-MM-DD' },
        add: 'Additional certification',
        remove: 'Remove certification'
    },
    skills: {
        hard: 'Hard Skills',
        soft: 'Soft Skills',
        languages: 'Languages',
        add: 'Add skill'
    },
    portfolio: {
        title: { label: 'Title', placeholder: 'My Portfolio' },
        url: { label: 'URL', placeholder: 'https://www.myportfolio.com' },
        add: 'Additional URL',
        remove: 'Remove URL'
    },
    questions: {},
    demographics: {
        gender: {
            label: 'What gender do you identify as?',
            options: [
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Non-Binary', value: 'non_binary' },
                { label: 'Choose not to Disclose', value: 'no_disclose' }
            ]
        },
        age: { label: 'What is your age?' },
        ethnicity: {
            label: 'What is your ethnicity',
            options: [
                { label: 'Arab', value: 'arab' },
                { label: 'Black ', value: 'black' },
                { label: 'East Asian', value: 'east_asian' },
                { label: 'Indigenous', value: 'indigenous' },
                { label: 'Latin American', value: 'latin_american' },
                { label: 'Middle Eastern', value: 'middle_eastern' },
                { label: 'Hawaiian', value: 'hawaiian' },
                { label: 'South Asian', value: 'south_asian' },
                { label: 'West Asian', value: 'west_asian' },
                { label: 'Other', value: 'not_listed' },
                { label: 'Choose not to Disclose', value: 'no_disclose' }
            ]
        },
        disability: {
            label: 'Do you identify as someone with a disability',
            options: [
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
                { label: 'No Disclose', value: 'no_disclose' }
            ]
        },
        lgbtq: {
            label: 'Do you identify as a member of the LGBTQ+ community',
            options: [
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
                { label: 'No Disclose', value: 'no_disclose' }
            ]
        }
    }
};

export default content;
