import { yupResolver } from '@hookform/resolvers/yup';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextButton } from '../../../global/components/Button/Button';
import ChipList from '../../../global/components/ChipList';
import Select from '../../../global/components/SelectField';
import TextInput from '../../../global/components/TextField/TextField';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import { FormSection, NextSteps, StyledButton, StyledSelect } from '../styles';

import content from './content';

import type { WorkingPreference } from '../../../../types/enum';
import type { User } from '../../../../types/user';
import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup
    .object()
    .shape({
        currentDesiredRole: yup
            .string()
            .oneOf([
                'product manager',
                'software engineer',
                'designer',
                'other'
            ])
            .required(commonContent.common.errors.required),
        currentDesiredLocation: yup
            .string()
            .oneOf(['toronto', 'montreal', 'vancouver'])
            .required(commonContent.common.errors.required),
        desiredRoles: yup.array().of(
            yup.object().shape({
                role: yup
                    .string()
                    .oneOf([
                        'product manager',
                        'software engineer',
                        'designer',
                        'other'
                    ])
                    .required(commonContent.common.errors.required)
            })
        ),
        desiredLocations: yup
            .array()
            .of(
                yup.object().shape({
                    location: yup
                        .string()
                        .oneOf(['toronto', 'montreal', 'vancouver'])
                        .required(commonContent.common.errors.required)
                })
            )
            .required(),
        desiredSalary: yup
            .number()
            .typeError(content.idealRole.errors.salaryNumber)
            .min(30000, content.idealRole.errors.salaryMin)
            .required(commonContent.common.errors.required),
        workingEnvironment: yup
            .string()
            .oneOf(['no_preference', 'office', 'remote', 'hybrid'])
            .required(commonContent.common.errors.required)
    })
    .required();

export default function IdealRoleSlide(props: {
    profile: User['idealRole'];
    updateProfile: (value: User['idealRole']) => void;
    prevURL: string;
    onClose: () => Promise<void>;
    isEdit?: boolean;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control,
        watch
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            currentDesiredRole: 'software engineer',
            currentDesiredLocation: 'toronto',
            desiredRoles: props.profile.desiredRoles.map((role) => ({ role })),
            desiredLocations: props.profile.desiredLocations.map(
                (location) => ({ location })
            ),
            desiredSalary: props.profile.desiredSalary.toString(),
            workingEnvironment: props.profile.workingEnvironment
        }
    });
    const {
        fields: roleFields,
        append: roleAppend,
        remove: roleRemove
    } = useFieldArray({
        control,
        name: 'desiredRoles'
    });
    const {
        fields: locationFields,
        append: locationAppend,
        remove: locationRemove
    } = useFieldArray({
        control,
        name: 'desiredLocations'
    });
    const currentDesiredRole = watch('currentDesiredRole');
    const currentDesiredLocation = watch('currentDesiredLocation');

    const onSubmit = (data: {
        desiredRoles: { role: string }[];
        desiredLocations: { location: string }[];
        workingEnvironment: WorkingPreference;
        desiredSalary: string;
    }) => {
        props.updateProfile({
            desiredRoles: data.desiredRoles.map((role) => role.role),
            desiredLocations: data.desiredLocations.map(
                (location) => location.location
            ),
            workingEnvironment: data.workingEnvironment,
            desiredSalary: parseInt(data.desiredSalary, 10)
        });
    };

    return (
        <ColumnCard
            closeScreen
            step={2}
            screenType={'candidate'}
            onClose={props.onClose}
            id={'idealRoleSlide'}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {commonContent.common.title}
                </Typography>
                <Typography>{commonContent.common.stepTwoSubtitle}</Typography>
                <ChipList
                    name='desired-roles'
                    values={roleFields.map((field) => field.role)}
                    addValue={() => {
                        if (
                            !roleFields
                                .map((field) => field.role)
                                .includes(currentDesiredRole)
                        ) {
                            roleAppend({ role: currentDesiredRole });
                        }
                    }}
                    removeValue={roleRemove}
                    control={control as unknown as Control<FieldValues>}
                >
                    <StyledSelect
                        id={'currentDesiredRole'}
                        label={content.idealRole.desiredRoles.label}
                        color='primary'
                        control={control as unknown as Control<FieldValues>}
                        options={content.idealRole.desiredRoles.options}
                        variant='outlined'
                    />
                </ChipList>
                <ChipList
                    name='desired-locations'
                    values={locationFields.map((field) => field.location)}
                    addValue={() => {
                        if (
                            !locationFields
                                .map((field) => field.location)
                                .includes(currentDesiredLocation)
                        ) {
                            locationAppend({
                                location: currentDesiredLocation
                            });
                        }
                    }}
                    removeValue={locationRemove}
                    control={control as unknown as Control<FieldValues>}
                >
                    <StyledSelect
                        id={'currentDesiredLocation'}
                        label={content.idealRole.desiredLocations.label}
                        color='primary'
                        control={control as unknown as Control<FieldValues>}
                        options={content.idealRole.desiredLocations.options}
                        variant='outlined'
                    />
                </ChipList>
                <TextInput
                    id='desiredSalary'
                    label={content.idealRole.desiredSalary.label}
                    color='primary'
                    error={!!errors.desiredSalary}
                    errorText={errors.desiredSalary?.message}
                    placeholder={content.idealRole.desiredSalary.placeholder}
                    control={control as unknown as Control<FieldValues>}
                    type='number'
                    startAdornment={
                        <InputAdornment position='start'>
                            {content.idealRole.desiredSalary.currencySymbol}
                        </InputAdornment>
                    }
                    sx={{ my: 2 }}
                />
                <Select
                    id='workingEnvironment'
                    label={content.idealRole.workingEnvironment.label}
                    color='primary'
                    error={!!errors.workingEnvironment}
                    errorText={errors.workingEnvironment?.message}
                    control={control as unknown as Control<FieldValues>}
                    options={content.idealRole.workingEnvironment.options}
                />
                <NextSteps>
                    {!props.isEdit && (
                        <TextButton to={props.prevURL}>
                            {commonContent.common.back}
                        </TextButton>
                    )}
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id='submit'
                    >
                        {props.isEdit ? 'Save' : commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
