import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextButton } from '../../../global/components/Button/Button';
import ChipList from '../../../global/components/ChipList';
import TextInput from '../../../global/components/TextField/TextField';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import { FormSection, NextSteps, StyledButton } from '../styles';

import type { UserProfile } from '../userProfileTypes';
import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup.object().shape({
    currentHard: yup.string(),
    currentSoft: yup.string(),
    currentLanguage: yup.string(),
    hard: yup.array().of(yup.object().shape({ skill: yup.string() })),
    soft: yup.array().of(yup.object().shape({ skill: yup.string() })),
    languages: yup.array().of(yup.object().shape({ skill: yup.string() }))
});

const StyledTextField = styled(TextInput)`
    flex-grow: 1;
`;

export default function SkillsSlide(props: {
    profile: UserProfile['skills'];
    updateProfile: (value: UserProfile['skills']) => void;
    prevURL: string;
    onClose: () => Promise<void>;
    isEdit?: boolean;
}) {
    const { handleSubmit, control, watch, setValue } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            currentHard: '',
            currentSoft: '',
            currentLanguage: '',
            hard: props.profile.hard.map((skill) => ({ skill })),
            soft: props.profile.soft.map((skill) => ({ skill })),
            languages: props.profile.languages.map((skill) => ({ skill }))
        }
    });
    const {
        fields: hardFields,
        append: hardAppend,
        remove: hardRemove
    } = useFieldArray({
        control,
        name: 'hard'
    });
    const {
        fields: softFields,
        append: softAppend,
        remove: softRemove
    } = useFieldArray({
        control,
        name: 'soft'
    });
    const {
        fields: languageFields,
        append: languageAppend,
        remove: languageRemove
    } = useFieldArray({
        control,
        name: 'languages'
    });
    const currentHard = watch('currentHard');
    const currentSoft = watch('currentSoft');
    const currentLanguage = watch('currentLanguage');

    const onSubmit = (data: {
        hard: { skill: string }[];
        soft: { skill: string }[];
        languages: { skill: string }[];
    }) => {
        props.updateProfile({
            hard: data.hard.map((skill) => skill.skill),
            soft: data.soft.map((skill) => skill.skill),
            languages: data.languages.map((skill) => skill.skill)
        });
    };

    return (
        <ColumnCard
            closeScreen
            step={3}
            screenType={'candidate'}
            onClose={props.onClose}
            id={'skillsSlide'}
            wide
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {commonContent.common.title}
                </Typography>
                <Typography>
                    {'Tell us about your skills and languages.'}
                </Typography>
                <ChipList
                    name='hard-skills'
                    values={hardFields.map((field) => field.skill)}
                    addValue={() => {
                        if (
                            currentHard &&
                            !hardFields
                                .map((field) => field.skill.toLowerCase())
                                .includes(currentHard.toLowerCase())
                        ) {
                            hardAppend({ skill: currentHard });
                            setValue('currentHard', '');
                        }
                    }}
                    removeValue={hardRemove}
                    control={control as unknown as Control<FieldValues>}
                >
                    <StyledTextField
                        id={'currentHard'}
                        color='primary'
                        variant='outlined'
                        label={'Enter Hard Skills'}
                        error={''}
                        control={control}
                    />
                </ChipList>
                <ChipList
                    name='soft-skills'
                    values={softFields.map((field) => field.skill)}
                    addValue={() => {
                        if (
                            currentSoft &&
                            !softFields
                                .map((field) => field.skill.toLowerCase())
                                .includes(currentSoft.toLowerCase())
                        ) {
                            softAppend({ skill: currentSoft });
                            setValue('currentSoft', '');
                        }
                    }}
                    removeValue={softRemove}
                    control={control as unknown as Control<FieldValues>}
                >
                    <StyledTextField
                        id={'currentSoft'}
                        color='primary'
                        variant='outlined'
                        label={'Enter Soft Skills'}
                        error={''}
                        control={control}
                    />
                </ChipList>
                <ChipList
                    name='languages'
                    values={languageFields.map((field) => field.skill)}
                    addValue={() => {
                        if (
                            currentLanguage &&
                            !languageFields
                                .map((field) => field.skill.toLowerCase())
                                .includes(currentLanguage.toLowerCase())
                        ) {
                            languageAppend({ skill: currentLanguage });
                            setValue('currentLanguage', '');
                        }
                    }}
                    removeValue={languageRemove}
                    control={control as unknown as Control<FieldValues>}
                >
                    <StyledTextField
                        id={'currentLanguage'}
                        color='primary'
                        variant='outlined'
                        label={'Enter Language Skills'}
                        error={''}
                        control={control}
                    />
                </ChipList>
                <NextSteps>
                    {!props.isEdit && (
                        <TextButton to={props.prevURL}>
                            {commonContent.common.back}
                        </TextButton>
                    )}
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id='submit'
                    >
                        {props.isEdit ? 'Save' : commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
