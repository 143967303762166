import moment from 'moment';

import {
    convertLocalMomentToUTCDateTime,
    convertUTCDateTimeToLocalMoment
} from '../../utils/convertDate';

import { convertCompanyResponseToCompany } from './company';
import { convertSimpleInterviewResponseToSimpleInterview } from './interview';
import { convertUserResponseToUser } from './user';

import type {
    Job,
    JobResponse,
    SimpleJob,
    SimpleJobResponse
} from '../../types/job';
import type { User } from '../../types/user';

export const createEmptyJob = (): SimpleJob => {
    const job: SimpleJob = {
        id: 0,
        companyID: 0,
        createdByID: 0,
        title: '',
        shortDescription: '',
        description: '',
        roleType: '',
        educationType: 'none',
        minSalary: 0,
        maxSalary: 0,
        hideSalary: false,
        minExperience: 0,
        maxExperience: 0,
        workPreference: 'no_preference',
        open: true,
        keywords: [],
        benefits: [],
        createdAt: moment()
    };
    return job;
};

export const convertSimpleJobResponseToSimpleJob = (
    response: SimpleJobResponse
): SimpleJob => {
    const job: SimpleJob = {
        id: response.id,
        companyID: response.company_id,
        createdByID: response.created_by_id,
        title: response.title,
        shortDescription: response.short_description,
        description: response.description,
        roleType: response.role_type,
        educationType: response.education_type,
        minSalary: response.min_salary,
        maxSalary: response.max_salary,
        hideSalary: response.hide_salary,
        minExperience: response.min_experience,
        maxExperience: response.max_experience,
        workPreference: response.work_preference,
        open: response.open,
        keywords: response.keywords,
        benefits: response.benefits,
        createdAt: convertUTCDateTimeToLocalMoment(response.created_at)
    };
    return job;
};

export const convertSimpleJobToSimpleJobResponse = (
    job: SimpleJob
): SimpleJobResponse => {
    const response: SimpleJobResponse = {
        id: job.id,
        company_id: job.companyID,
        created_by_id: job.createdByID,
        title: job.title,
        short_description: job.shortDescription,
        description: job.description,
        role_type: job.roleType,
        education_type: job.educationType,
        min_salary: job.minSalary,
        max_salary: job.maxSalary,
        hide_salary: job.hideSalary,
        min_experience: job.minExperience,
        max_experience: job.maxExperience,
        work_preference: job.workPreference,
        open: job.open,
        keywords: job.keywords,
        benefits: job.benefits,
        created_at: convertLocalMomentToUTCDateTime(job.createdAt)
    };
    return response;
};

export const convertJobResponseToJob = (response: JobResponse): Job => ({
    ...convertSimpleJobResponseToSimpleJob(response),
    company: convertCompanyResponseToCompany(response.company),
    createdBy: convertUserResponseToUser(response.created_by) as User,
    interviews: response.interviews.map((interviewResponse) =>
        convertSimpleInterviewResponseToSimpleInterview(interviewResponse)
    ),
    saved: response.saved
});
