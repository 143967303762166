import firebase from 'firebase/compat/app';
import React, {
    type ComponentType,
    createContext,
    type ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
    createCompany as createCompanyEndpoint,
    getUserCompanies,
    updateCompany as updateCompanyEndpoint
} from '../../services/company';
import { createEmptyCompany } from '../../services/convert/company';
import { createEmptyUser } from '../../services/convert/user';
import {
    createBusiness,
    createCandidate,
    getUser,
    setUserOffline,
    setUserOnline,
    updateUser as updateUserEndpoint
} from '../../services/user';
import { type Company } from '../../types/company';
import { type User } from '../../types/user';
import localStorageUtil from '../../utils/localStorage';
import removeFalsyObjects from '../../utils/removeFalsy';

export type UserContextType = {
    user: User;
    setUser: (user: User) => void;
    updateUser: (updatedData?: Partial<User> | null) => Promise<void>;
    companies: Company[];
    company: Company;
    setCompany: (company: Partial<Company>) => void;
    createOrUpdateCompany: (company?: Partial<Company> | null) => Promise<void>;
    setCompanyID: (companyID: number) => void;
};

export const UserContext = createContext<UserContextType>({
    user: createEmptyUser(),
    setUser: () => {},
    updateUser: () => Promise.resolve(),
    companies: [] as Company[],
    company: createEmptyCompany(),
    setCompany: () => {},
    createOrUpdateCompany: () => Promise.resolve(),
    setCompanyID: () => {}
});

export const UserProvider = ({
    children,
    firebaseUser
}: {
    children: ReactNode;
    firebaseUser: firebase.User;
}) => {
    const [user, setUser] = useState<User>(createEmptyUser());
    const [companies, setCompanies] = useState<Company[]>([]);
    const [companyID, setCompanyID] = useState(0);
    const userRef = useRef(user);
    const { companyID: pathCompanyID } = useParams<{ companyID: string }>();
    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        userRef.current = user;
    }, [user]);

    useEffect(() => {
        const blockedRoutes = [
            'job',
            'network',
            'upcominginterviews',
            'interview',
            'statistics',
            'messaging'
        ];
        if (companies.length > 0 && !companies.some((c) => c.subscription)) {
            if (
                user.levylRole === 'business' &&
                blockedRoutes.some((route) => pathname.includes(`/${route}`))
            ) {
                history.push(`/secure/${user.userID}/business/dashboard`);
            }
        }
    });

    const company = useMemo(
        () => companies.find((c) => c.id === companyID) ?? createEmptyCompany(),
        [companies, companyID]
    );

    useEffect(() => {
        if (pathCompanyID) {
            setCompanyID(parseInt(pathCompanyID, 10));
        }
    }, [pathCompanyID]);

    useEffect(() => {
        if (user.id !== 0) {
            setUserOnline(user.id);
        }
        return () => {
            if (user.id !== 0) {
                setUserOffline(user.id);
            }
        };
    }, [user.id]);

    const hydrateUpdatedUser: (updatedData: User) => User = (updatedData) => {
        const hydratedUser = { ...updatedData };
        type falseKeyType =
            | 'experience'
            | 'education'
            | 'certification'
            | 'portfolio'
            | 'interestedQuestions';
        const falsyLists: falseKeyType[] = [
            'experience',
            'education',
            'certification',
            'portfolio',
            'interestedQuestions'
        ];
        const emptyUser = createEmptyUser();
        falsyLists.forEach((key: falseKeyType) => {
            if (hydratedUser[key].length === 0) {
                hydratedUser[key] = emptyUser[key] as any;
            }
        });
        return hydratedUser;
    };

    const updateUser = useCallback(
        (updatedData: Partial<User> | null = null) => {
            const currentUser = userRef.current;
            let updatedUser = { ...currentUser };
            if (updatedData) {
                updatedUser = {
                    ...currentUser,
                    ...(updatedData as object)
                };
            }
            const possibleFalsy = {
                experience: updatedUser.experience,
                education: updatedUser.education,
                certification: updatedUser.certification,
                skills: updatedUser.skills,
                portfolio: updatedUser.portfolio,
                interestedQuestions: updatedUser.interestedQuestions
            };
            updatedUser = {
                ...updatedUser,
                ...removeFalsyObjects(possibleFalsy)
            };
            return updateUserEndpoint(updatedUser)
                .then((response: User) => {
                    if (response) {
                        const hydratedUser = hydrateUpdatedUser(response);
                        setUser(hydratedUser);
                    }
                })
                .catch((error: any) => {
                    console.log('error', error); // eslint-disable-line no-console
                });
        },
        []
    );

    useEffect(() => {
        const waitlistId = localStorageUtil.getItem('waitlistId');
        if (
            firebaseUser &&
            user.id === 0 &&
            waitlistId &&
            pathname.includes('signup')
        ) {
            getUser(waitlistId).then((response: User) => {
                updateUserEndpoint({
                    ...response,
                    userID: firebaseUser.uid,
                    waitlist: false
                }).then((updatedResponse: User) => {
                    if (updatedResponse) {
                        const hydratedUser =
                            hydrateUpdatedUser(updatedResponse);
                        setUser(hydratedUser);
                    }
                });
            });
        } else if (firebaseUser && user.id === 0 && !waitlistId) {
            getUser(firebaseUser.uid).then((response: User) => {
                if (response) {
                    const hydratedUser = hydrateUpdatedUser(response);
                    setUser(hydratedUser);
                } else if (
                    pathname.includes('candidate') &&
                    pathname.includes('create')
                ) {
                    createCandidate(
                        firebaseUser.email ?? '',
                        firebaseUser.uid
                    ).then((createResponse: User) => {
                        const { id, userID, levylRole, email } = createResponse;
                        const updatedResponse = {
                            ...createEmptyUser(),
                            id,
                            userID,
                            levylRole,
                            email
                        };
                        setUser(updatedResponse);
                    });
                } else if (
                    pathname.includes('business') &&
                    pathname.includes('create')
                ) {
                    createBusiness(
                        firebaseUser.email ?? '',
                        firebaseUser.uid
                    ).then((createResponse: User) => {
                        const { id, userID, levylRole, email } = createResponse;
                        const updatedResponse = {
                            ...createEmptyUser(),
                            id,
                            userID,
                            levylRole,
                            email
                        };
                        setUser(updatedResponse);
                    });
                } else {
                    updateUser({
                        userID: firebaseUser.uid,
                        email: firebaseUser.email ?? ''
                    });
                }
            });
        }
    }, [firebaseUser, pathname, user.id, updateUser]);

    useEffect(() => {
        if (user.id !== 0) {
            localStorageUtil.removeItem('waitlistId');
        }
    }, [user.id]);

    useEffect(() => {
        if (
            user.id !== 0 &&
            user.levylRole === 'business' &&
            companies.length === 0
        ) {
            getUserCompanies(user.id).then((response: Company[]) => {
                if (response && response.length > 0) {
                    setCompanies(response);
                }
            });
        }
    }, [user.id, user.userID, user.levylRole, companies.length]);

    const setCompany = (companyData: Partial<Company>) => {
        if (!companies.find((c) => c.id === companyID)) {
            if (companyID === 0) {
                setCompanies([
                    ...companies,
                    {
                        ...createEmptyCompany(),
                        ...companyData,
                        createdByID: user.id
                    }
                ]);
            }
        } else {
            setCompanies(
                companies.map((c) =>
                    c.id === companyID ? { ...c, ...companyData } : c
                )
            );
        }
    };

    const createOrUpdateCompany = (
        companyData: Partial<Company> | null = null
    ) => {
        let updatingCompany = companies.find((c) => c.id === companyID) ?? {
            ...createEmptyCompany(),
            createdByID: user.id
        };
        if (companyData) {
            updatingCompany = {
                ...updatingCompany,
                ...companyData
            };
        }
        const updatePromise =
            companyID === 0 ? createCompanyEndpoint : updateCompanyEndpoint;
        return updatePromise(updatingCompany).then((response: Company) => {
            setCompanyID(response.id);
            setCompanies(
                companies.map((c) => (c.id === companyID ? response : c))
            );
        });
    };

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                updateUser,
                companies,
                company,
                setCompany,
                createOrUpdateCompany,
                setCompanyID
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const withUserContext = <P extends object>(
    Component: ComponentType<P & { userContext: UserContextType }>
) => {
    const parent = (props: any) => (
        <UserContext.Consumer>
            {(userContext) => (
                <Component {...props} userContext={userContext} />
            )}
        </UserContext.Consumer>
    );
    const componentName =
        Component.displayName || Component.name || 'Component';
    parent.displayName = `withUserContext(${componentName})`;
    return parent;
};
