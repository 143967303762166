import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
    createCompanyPaymentCheckout,
    manageSubscription,
    updatePlan
} from '../../../../services/company';
import { UserContext } from '../../../context/UserContext';
import { TextButton } from '../../../global/components/Button/Button';
import Modal from '../../../global/components/Modal/Modal';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import { NextSteps, StyledButton } from '../styles';

import content from './content';

import type { Company } from '../../../../types/company';

const calculateMonthlyCost = (roleCount: number) => {
    if (roleCount <= 3) {
        return 30;
    }
    if (roleCount <= 10) {
        return 30 + (roleCount - 3) * 7.5;
    }
    return 30 + 7 * 7.5 + (roleCount - 10) * 5;
};

const PlanSelector = ({
    activeRoles,
    setActiveRoles
}: {
    activeRoles: number;
    setActiveRoles: (value: number) => void;
}) => (
    <Card variant='outlined' sx={{ p: 3, mt: 2, borderRadius: 4 }}>
        <Typography
            variant={'h4'}
            style={{
                display: 'flex',
                alignItems: 'baseline',
                fontWeight: 700,
                fontSize: 40,
                marginBottom: '16px'
            }}
        >
            {`$${calculateMonthlyCost(activeRoles)} `}
            <Typography variant={'body2'} component='span' sx={{ ml: 0.5 }}>
                {' / month'}
            </Typography>
        </Typography>
        <Slider
            aria-label='Active Roles'
            defaultValue={3}
            valueLabelDisplay='auto'
            shiftStep={5}
            step={1}
            min={3}
            max={50}
            onChange={(_, value) => setActiveRoles(value as number)}
            value={activeRoles}
        />
        <Typography variant={'h6'} fontWeight={700} sx={{ mt: 2, pb: 0 }}>
            {'Base Plan'}
        </Typography>
        <Typography variant={'body1'} sx={{ ml: 1, mt: 1 }}>
            {'3 active roles, $30 / month'}
        </Typography>
        <Typography
            variant={'h6'}
            style={{
                fontWeight: 700
            }}
            sx={{ mt: 2, pb: 0 }}
        >
            {'For Additional Active Roles'}
        </Typography>
        <Typography variant={'body1'} sx={{ ml: 1, mt: 1 }}>
            {'For each role from 4 - 10, the cost is $7.50 / month per role'}
        </Typography>
        <Typography variant={'body1'} sx={{ ml: 1, mt: 1 }}>
            {'For each role from 11 - 50, the cost is $5.00 / month per role'}
        </Typography>
        <Typography variant={'h6'} fontWeight={700} sx={{ mt: 2, pb: 0 }}>
            {'Looking for more?'}
        </Typography>
        <Typography variant={'body1'} sx={{ ml: 1, mt: 1 }}>
            {'Feel free to reach out to us at contact@levyl.app'}
        </Typography>
    </Card>
);

const CurrentPlanCard = ({
    companyID,
    existingActiveRoles
}: {
    companyID: number;
    existingActiveRoles: number;
}) => (
    <Card variant='outlined' sx={{ p: 3, mt: 2, borderRadius: 4 }}>
        <Typography variant={'h6'} fontWeight={700} sx={{ pb: 0 }}>
            {'Current Plan'}
        </Typography>
        <Typography variant={'body1'} sx={{ ml: 1, mt: 1 }}>
            <Typography color={'primary'} component='span' fontWeight={'700'}>
                {existingActiveRoles}
            </Typography>
            {' active roles, '}
            <Typography color={'primary'} component='span' fontWeight={'700'}>
                {`$${calculateMonthlyCost(existingActiveRoles)}`}
            </Typography>
            {' / month'}
        </Typography>
        <Button
            variant={'outlined'}
            style={{ textTransform: 'none' }}
            sx={{ ml: 1, mt: 2 }}
            onClick={() =>
                manageSubscription(companyID, window.location.href).then(
                    (response: { url: string }) => {
                        window.location.href = response.url;
                    }
                )
            }
        >
            {'Manage Subscription'}
        </Button>
    </Card>
);

export default function BusinessPlans(props: {
    company: Company;
    prevURL: string;
    onClose: () => Promise<void>;
    isEdit?: boolean;
}) {
    const { company } = props;
    const { user } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [activeRoles, setActiveRoles] = useState(3);
    const [existingActiveRoles, setExistingActiveRoles] = useState(0);
    const [modal, setModal] = useState(false);

    const onPaymentSubmit = () => {
        if (
            company.id &&
            activeRoles !== existingActiveRoles &&
            company.subscription
        ) {
            setIsLoading(true);
            setModal(true);
        } else if (company.id && !company.subscription) {
            setIsLoading(true);
            createCompanyPaymentCheckout(
                company.id,
                activeRoles,
                props.isEdit
                    ? window.location.href
                    : `/secure/${user.userID}/business/dashboard`
            ).then((response: any) => {
                setIsLoading(false);
                window.location.href = response.url;
            });
        } else {
            setRedirect(true);
        }
    };

    useEffect(() => {
        if (company.subscription) {
            setExistingActiveRoles(company.subscription.activeRoles || 0);
            setActiveRoles(company.subscription.activeRoles || 3);
        }
    }, [company]);

    return (
        <>
            <ColumnCard
                closeScreen
                step={3}
                screenType={'business'}
                onClose={props.onClose}
                wide
            >
                <Typography variant='h2' fontWeight='400' sx={{ mb: 2 }}>
                    {content.company.plan.title}
                    <Typography variant='h2' color={'primary'} component='span'>
                        {`Levyl Plan - ${activeRoles}`}
                    </Typography>
                </Typography>
                <Typography variant={'body1'} sx={{ ml: 1 }}>
                    {'You will have access to '}
                    <Typography
                        color={'primary'}
                        component='span'
                        fontWeight={'700'}
                    >
                        {activeRoles}
                    </Typography>
                    {` active roles, which means you will be able to hire for up to ${activeRoles} roles at a time`}
                </Typography>
                <PlanSelector
                    activeRoles={activeRoles}
                    setActiveRoles={setActiveRoles}
                />
                {company.subscription && props.isEdit && (
                    <CurrentPlanCard
                        companyID={company.id}
                        existingActiveRoles={existingActiveRoles}
                    />
                )}
                <NextSteps>
                    {!props.isEdit && (
                        <TextButton to={props.prevURL}>
                            {commonContent.common.cancel}
                        </TextButton>
                    )}
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        onClick={onPaymentSubmit}
                        id={'submit'}
                    >
                        {isLoading && <CircularProgress color='primary' />}
                        {!isLoading &&
                            (props.isEdit
                                ? 'Save'
                                : commonContent.common.confirm)}
                    </StyledButton>
                </NextSteps>
            </ColumnCard>
            <Modal
                heading={'Update Plan'}
                content={`You are about to update your plan from ${existingActiveRoles} active roles to ${activeRoles} active roles. This will change your monthly subscription cost to $${calculateMonthlyCost(
                    activeRoles
                )}. Your plan will be prorated to reflect unused costs.`}
                open={modal}
                onClose={() => {
                    setModal(false);
                    setIsLoading(false);
                }}
                primaryCTAContent='Update'
                primaryCTAAction={() =>
                    updatePlan(company.id, activeRoles).then(() => {
                        setModal(false);
                        setIsLoading(false);
                    })
                }
            />
            {redirect && (
                <Redirect to={`/secure/${user.userID}/business/dashboard`} />
            )}
        </>
    );
}
