import Typography from '@mui/material/Typography';
import React from 'react';

import jobPosting from '../../../static/illustrations/job-posting.svg';
import Button from '../../global/components/Button/Button';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';

export default function CompleteSlide(props: { onClose: () => Promise<void> }) {
    return (
        <ColumnCard
            closeScreen
            screenType={'job'}
            onClose={props.onClose}
            image={{ src: jobPosting, alt: 'Job posting' }}
            wide
        >
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant={'h1'} color={'primary'}>
                        {'Your job posting is live!'}
                    </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant='body2'
                        style={{
                            marginTop: '0.875rem'
                        }}
                    >
                        {'Sit back, and let us bring great candidates to you.'}
                    </Typography>
                    <Typography
                        variant='body2'
                        style={{
                            marginBottom: '2.5rem'
                        }}
                    >
                        {
                            "You'll be notified each time an interview is scheduled"
                        }
                    </Typography>
                    <div style={{ marginRight: 'auto' }}>
                        <Button onClick={props.onClose} variant='contained'>
                            {'Go to dashboard'}
                        </Button>
                    </div>
                </div>
            </div>
        </ColumnCard>
    );
}
