import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { TextButton } from '../../../global/components/Button/Button';
import Upload from '../../../global/components/UploadFile';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import { FormSection, NextSteps, StyledButton } from '../styles';

import content from './content';

import type { UserProfile } from '../userProfileTypes';

export default function ResumeSlide(props: {
    profile: UserProfile['resume'];
    updateProfile: (value: UserProfile['resume']) => void;
    prevURL: string;
    onClose: () => Promise<void>;
    isEdit?: boolean;
}) {
    const [resumeUrl, setResumeUrl] = useState(props.profile);
    const { uid } = useParams<{ uid: string }>();

    const onSubmit = () => {
        props.updateProfile(resumeUrl);
    };

    return (
        <ColumnCard
            closeScreen
            step={3}
            screenType={'candidate'}
            onClose={props.onClose}
            id={'resumeSlide'}
        >
            <FormSection onSubmit={onSubmit}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {commonContent.common.title}
                </Typography>
                <Typography>
                    {
                        'Upload your resume and we will use it to fill out some of your profile information.'
                    }
                </Typography>
                <Typography
                    variant='h6'
                    component='h2'
                    fontWeight='400'
                    sx={{ my: 2 }}
                >
                    {content.resume.resume.title}
                </Typography>
                <Typography>{content.resume.resume.subtitle}</Typography>
                <div
                    style={{
                        display: 'flex',
                        marginTop: '2rem',
                        flexDirection: 'column'
                    }}
                >
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <Upload
                            userId={uid}
                            value={resumeUrl}
                            setValue={setResumeUrl}
                            id={'resume'}
                            label={content.resume.resume.label}
                            acceptPDF
                        />
                    </div>
                </div>
                <NextSteps>
                    {!props.isEdit && (
                        <TextButton to={props.prevURL}>
                            {commonContent.common.back}
                        </TextButton>
                    )}
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id='submit'
                    >
                        {props.isEdit ? 'Save' : commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
