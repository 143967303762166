export const getMinExperience = (experience?: string) => {
    switch (experience) {
        case '0-2':
            return 0;
        case '2-4':
            return 2;
        case '4-9':
            return 4;
        default:
            return 10;
    }
};

export const getMaxExperience = (experience?: string) => {
    switch (experience) {
        case '0-2':
            return 2;
        case '2-4':
            return 4;
        case '4-9':
            return 9;
        default:
            return 10;
    }
};
