import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

import Button from '../global/components/Button/Button';
import NoActionModal from '../global/components/Modal/NoActionModal';

const content = {
    joinedWaitlist: {
        title: 'You have joined the waitlist!',
        content: [
            'You should receive an email confirmation shortly. Expect to receive another email soon about creating your profile. We will start inviting users in the coming months.',
            'The email will be sent from notifications@levyl.app',
            'If you use a spam filter, please add this address to the whitelist to ensure that you will receive the waitlist confirmation.',
            'If you have any questions, please contact us at support@levyl.app'
        ],
        buttons: []
    },
    onWaitlist: {
        title: 'You are already on the waitlist!',
        content: [
            'Please check your email for a confirmation message from us.',
            'The email will be sent from notifications@levyl.app',
            'We are currently only accepting users from our waitlist. We will start inviting users in the coming months.',
            'If you use a spam filter, please add this address to the whitelist to ensure that you will receive the waitlist confirmation.',
            'If you have any questions, please contact us at support@levyl.app'
        ],
        buttons: []
    },
    doesNotExist: {
        title: "We can't find you on our waitlist!",
        content: [
            "It looks like we can't find your email address in our records. We are currently only accepting users from our waitlist",
            'Please join the waitlist from our homepage for candidates or companies.',
            'If you have any questions, please contact us at support@levyl.app'
        ],
        buttons: [
            {
                content: 'Candidate Waitlist',
                link: '/public/career-seekers'
            },
            {
                content: 'Company Waitlist',
                link: '/public/companies'
            }
        ]
    },
    alreadyAccepted: {
        title: "You've already been invited to start using Levyl!",
        content: [
            "It looks like you've already been invited to join Levyl. If you haven't already, please sign up or login now!",
            'Please check your email for a confirmation message from us. The email will be sent from notifications@levyl.app',
            'If you use a spam filter, please add this address to the whitelist to ensure that you will receive the waitlist confirmation.',
            'If you have any questions, please contact us at support@levyl.app'
        ],
        buttons: [
            {
                content: 'Levyl Login & Signup',
                link: '/login'
            }
        ]
    },
    googleSignUp: {
        title: 'Check your email for sign up link',
        content: [
            'If you have signed up for the waitlist, please check your email inviting you to Complete Profile.',
            'If you have not signed up for the waitlist, please join the waitlist from our homepage for candidates or companies.',
            'If you have any questions, please contact us at support@levyl.app'
        ],
        buttons: [
            {
                content: 'Candidate Waitlist',
                link: '/public/career-seekers'
            },
            {
                content: 'Company Waitlist',
                link: '/public/companies'
            }
        ]
    }
};

const ModalContent = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
`;

const ButtonContainer = styled('div')`
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
`;

export interface WaitlistProps {
    open: boolean;
    onClose: () => void;
    waitlistType:
        | 'googleSignUp'
        | 'joinedWaitlist'
        | 'onWaitlist'
        | 'doesNotExist'
        | 'alreadyAccepted';
    closeAndRedirect?: (redirectURL: string) => void;
}

export default function WaitlistModal(props: WaitlistProps) {
    return (
        <NoActionModal
            heading={content[props.waitlistType].title}
            open={props.open}
            onClose={props.onClose}
        >
            <ModalContent>
                {content[props.waitlistType].content.map(
                    (contentPiece, index) => (
                        <Typography
                            variant={'body2'}
                            color={'text.secondary'}
                            key={index}
                        >
                            {contentPiece}
                        </Typography>
                    )
                )}
                <ButtonContainer>
                    {content[props.waitlistType].buttons.map(
                        (buttonInfo, index) => (
                            <div key={index}>
                                {props.closeAndRedirect ? (
                                    <Button
                                        onClick={() =>
                                            props.closeAndRedirect &&
                                            props.closeAndRedirect(
                                                buttonInfo.link
                                            )
                                        }
                                        variant='outlined'
                                    >
                                        {buttonInfo.content}
                                    </Button>
                                ) : (
                                    <Button
                                        to={buttonInfo.link}
                                        variant='outlined'
                                    >
                                        {buttonInfo.content}
                                    </Button>
                                )}
                            </div>
                        )
                    )}
                </ButtonContainer>
            </ModalContent>
        </NoActionModal>
    );
}
