import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextButton } from '../../../global/components/Button/Button';
import Select from '../../../global/components/SelectField';
import TextInput from '../../../global/components/TextField/TextField';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import { FormSection, NextSteps, StyledButton } from '../styles';

import content from './content';

import type { User } from '../../../../types/user';
import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup.object().shape({
    gender: yup.string().oneOf(['male', 'female', 'non_binary', 'no_disclose']),
    age: yup.number(),
    ethnicity: yup
        .string()
        .oneOf([
            'arab',
            'black',
            'east_asian',
            'indigenous',
            'latin_american',
            'middle_eastern',
            'hawaiian',
            'south_asian',
            'west_asian',
            'other',
            'no_disclose'
        ]),
    disability: yup.string().oneOf(['yes', 'no', 'no_disclose']),
    lgbtq: yup.string().oneOf(['yes', 'no', 'no_disclose'])
});

export default function DemographicSlide(props: {
    profile: User['demographics'];
    updateProfile: (value: User['demographics']) => void;
    submitProfile: (data: Partial<User>) => void;
    prevURL: string;
    onClose: () => Promise<void>;
    isEdit?: boolean;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: props.profile
    });

    const onSubmit = (data: User['demographics']) => {
        props.submitProfile({ demographics: data });
    };

    return (
        <ColumnCard
            closeScreen
            step={3}
            screenType={'candidate'}
            onClose={props.onClose}
            id={'demographicSlide'}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {commonContent.common.title}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                    {
                        'Completely optional demographic information, any use of this data is anonymized and used for statistical purposes only.'
                    }
                </Typography>
                <Select
                    id='gender'
                    label={content.demographics.gender.label}
                    color='primary'
                    error={!!errors.gender}
                    errorText={errors.gender?.message}
                    control={control as unknown as Control<FieldValues>}
                    options={content.demographics.gender.options}
                />
                <TextInput
                    id='age'
                    label={content.demographics.age.label}
                    color='primary'
                    error={!!errors.age}
                    errorText={errors.age?.message}
                    placeholder={''}
                    control={control as unknown as Control<FieldValues>}
                />
                <Select
                    id='ethnicity'
                    label={content.demographics.ethnicity.label}
                    color='primary'
                    error={!!errors.ethnicity}
                    errorText={errors.ethnicity?.message}
                    control={control as unknown as Control<FieldValues>}
                    options={content.demographics.ethnicity.options}
                />
                <Select
                    id='disability'
                    label={content.demographics.disability.label}
                    color='primary'
                    error={!!errors.disability}
                    errorText={errors.disability?.message}
                    control={control as unknown as Control<FieldValues>}
                    options={content.demographics.disability.options}
                />
                <Select
                    id='lgbtq'
                    label={content.demographics.lgbtq.label}
                    color='primary'
                    error={!!errors.lgbtq}
                    errorText={errors.lgbtq?.message}
                    control={control as unknown as Control<FieldValues>}
                    options={content.demographics.lgbtq.options}
                />
                <NextSteps>
                    {!props.isEdit && (
                        <TextButton to={props.prevURL}>
                            {commonContent.common.back}
                        </TextButton>
                    )}
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id='submit'
                    >
                        {props.isEdit ? 'Save' : commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
