import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import React, { useContext } from 'react';

import thumbsUp from '../../../../static/icons/thumbs-up.svg';
import { UserContext } from '../../../context/UserContext';
import Button from '../../../global/components/Button/Button';
import { BaseContainer } from '../../../global/Core';

import EmployerJobList from './EmployerJobList';

import type { User } from '../../../../types/user';

const NoCompanyCard = ({ user }: { user: User }) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '-1.5rem'
        }}
    >
        <Typography variant={'h2'} color={'text.secondary'}>
            {'Thank you for signing up!'}
        </Typography>
        <Typography
            variant='body2'
            style={{
                display: 'flex',
                marginTop: '0.875rem',
                marginBottom: '1.5rem'
            }}
            color={'text.secondary'}
        >
            {
                'In order to post a job for this company, please create your company profile.'
            }
        </Typography>
        <div style={{ marginRight: 'auto' }}>
            <Button
                variant='outlined'
                to={`/create/${user.userID}/business/companypage`}
            >
                {'Create Company Profile'}
            </Button>
        </div>
    </div>
);

export default function EmployerDashboard() {
    const { user, companies } = useContext(UserContext);
    return (
        <BaseContainer>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '4rem'
                }}
            >
                <Typography variant={'h1'} color={'text.secondary'}>
                    {user.name.firstName
                        ? `Hi, ${user.name.firstName}!`
                        : 'Welcome to Levyl!'}
                </Typography>
                <img src={thumbsUp} style={{ marginLeft: '1.5rem' }} />
            </div>
            {companies.length === 0 ? <NoCompanyCard user={user} /> : null}
            {companies.map((company, index) => (
                <>
                    <EmployerJobList
                        key={company.id}
                        user={user}
                        company={company}
                    />
                    {index < companies.length - 1 && (
                        <Divider variant='middle' flexItem sx={{ my: 4 }} />
                    )}
                </>
            ))}
        </BaseContainer>
    );
}
