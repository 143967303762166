import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import {
    Link,
    Redirect,
    useHistory,
    useLocation,
    useParams
} from 'react-router-dom';

import { bookInterview } from '../../../services/interviews';
import { getJob } from '../../../services/jobs';
import offerRoleImg from '../../../static/illustrations/offer-role.svg';
import { UserContext } from '../../context/UserContext';
import Button, {
    BackButton,
    TextButton
} from '../../global/components/Button/Button';
import { BaseCard, BaseContainer, ProfilePicture } from '../../global/Core';
import PositiveModal from '../../global/layouts/PositiveModal/PositiveModal';

import Calendar from './Calendar';

import type { DateYMDString } from './types';
import type { SimpleInterview } from '../../../types/interview';
import type { Job } from '../../../types/job';

const CardHeader = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
`;

const CompanyButton = styled(ButtonBase)`
    display: flex;
    gap: 0.5rem;
`;

const JobContent = styled('div')`
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    @media (max-width: 35rem) {
        padding-left: 0rem;
        padding-right: 0rem;
    }
`;

const JobTitle = styled(Typography)`
    color: rgba(0, 0, 0, 0.6);
`;

export const SelectionSection = styled('div')`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    width: 100%;
    padding-top: 0.5rem;
`;

const StyledButton = styled(Button)`
    min-width: 9rem;
    max-height: 4rem;
    flex: 0;
`;

const SingleInterviewSection = styled('div')`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    min-width: 19rem;
    justify-content: center;
    @media (max-width: 69rem) {
        min-width: unset;
    }
`;

const CalendarSection = styled('div')`
    display: flex;
    gap: 1.5rem;
    @media (max-width: 69rem) {
        flex-direction: column;
    }
`;

const CalendarContainer = styled('div')`
    flex: 1;
    display: flex;
    justify-content: center;
`;

const InterviewSection = styled('div')`
    flex: 0 1 min-content;
`;

const ButtonContainer = styled('div')`
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    @media (max-width: 25rem) {
        flex-direction: column-reverse;
    }
`;

export default function ScheduleInterview() {
    const [date, setDate] = React.useState<DateYMDString>(
        moment().format('YYYY-MM-DD') as DateYMDString
    );
    const [modal, setModal] = React.useState<boolean>(false);
    const [interviews, setInterviews] = React.useState<SimpleInterview[]>([]);
    const [job, setJob] = React.useState<Job>();
    const [currentInterview, setCurrentInterview] =
        React.useState<SimpleInterview>();
    const [redirect, setRedirect] = React.useState<boolean>(false);
    const history = useHistory();

    const { jobID } = useParams<{ jobID: string }>();
    const { user } = useContext(UserContext);

    const { pathname } = useLocation();

    const isFollowup = pathname.includes('schedulefollowup');

    useEffect(() => {
        if (date !== currentInterview?.startTime.format('YYYY-MM-DD')) {
            setCurrentInterview(undefined);
        }
    }, [currentInterview, date]);

    useEffect(() => {
        getJob(parseInt(jobID, 10)).then((res: Job) => {
            setJob(res);
            setInterviews(
                res.interviews.filter((interview) =>
                    isFollowup
                        ? interview.status === 'follow_up_requested'
                        : interview.status === 'available'
                )
            );
        });
    }, [jobID, isFollowup]);

    return (
        <>
            <BaseContainer width={60}>
                <BaseCard variant='outlined'>
                    <CardHeader>
                        <BackButton
                            onClick={() => {
                                history.goBack();
                            }}
                            startIcon={<KeyboardBackspaceIcon />}
                            color='primary'
                        />
                        <CompanyButton
                            component={Link}
                            to={`/secure/${user.userID}/company/${job?.companyID}`}
                        >
                            <ProfilePicture
                                src={job?.company.logoURL}
                                size={2}
                            />
                            <Typography variant={'body2'} color='primary'>
                                {` ${job?.company.name}`}
                            </Typography>
                        </CompanyButton>
                    </CardHeader>
                    <JobContent>
                        <JobTitle variant={'h6'} fontWeight={700}>
                            {job?.title}
                        </JobTitle>
                        <Typography>{job?.company.location}</Typography>
                        <Typography>
                            {`Posted: ${moment().diff(
                                job?.createdAt,
                                'days'
                            )} days ago`}
                        </Typography>
                        <Typography sx={{ mt: 4 }}>
                            {"Let's book your interview!"}
                        </Typography>
                        <Typography sx={{ mb: isFollowup ? 2 : 4 }}>
                            {
                                'Choose an available date and pick a time that works for you.'
                            }
                        </Typography>
                        {isFollowup && (
                            <div style={{ marginBottom: '1rem' }}>
                                <Typography>
                                    {
                                        'None of these times work for you? Contact your interviewer to reschedule.'
                                    }
                                </Typography>
                                <TextButton
                                    to={`/secure/${user.userID}/messaging/${interviews?.[0]?.messageChatURL}`}
                                >
                                    {'Contact Interviewer'}
                                </TextButton>
                            </div>
                        )}
                        <CalendarSection>
                            <CalendarContainer>
                                <Calendar
                                    date={date}
                                    setDate={setDate}
                                    availableDates={[
                                        ...new Set(
                                            interviews.map(
                                                (interview) =>
                                                    interview.startTime
                                                        .format()
                                                        .split(
                                                            'T'
                                                        )[0] as DateYMDString
                                            )
                                        )
                                    ]}
                                />
                            </CalendarContainer>
                            <InterviewSection>
                                <SingleInterviewSection>
                                    {interviews
                                        .filter(
                                            (interview) =>
                                                interview.startTime.format(
                                                    'YYYY-MM-DD'
                                                ) === date
                                        )
                                        .map((interview) => (
                                            <StyledButton
                                                variant={
                                                    currentInterview?.id ===
                                                    interview.id
                                                        ? 'contained'
                                                        : 'outlined'
                                                }
                                                key={interview.id}
                                                onClick={() =>
                                                    setCurrentInterview(
                                                        interview
                                                    )
                                                }
                                            >
                                                {interview.startTime.format(
                                                    'h:mm A'
                                                )}
                                            </StyledButton>
                                        ))}
                                </SingleInterviewSection>
                            </InterviewSection>
                        </CalendarSection>
                        <ButtonContainer>
                            <Button
                                variant={'text'}
                                onClick={() => history.goBack()}
                            >
                                {'Cancel'}
                            </Button>
                            <Button
                                variant={'outlined'}
                                onClick={() => setModal(true)}
                                disabled={!currentInterview}
                            >
                                {'Book Interview'}
                            </Button>
                        </ButtonContainer>
                    </JobContent>
                </BaseCard>
            </BaseContainer>
            {modal && (
                <PositiveModal
                    open={modal}
                    onClick={() => {
                        bookInterview(currentInterview?.id ?? 0, user.id).then(
                            setRedirect(true)
                        );
                    }}
                    onClose={() => setRedirect(true)}
                    title={'Nice, you booked an interview!'}
                    subtitle={`You're all set to meet ${
                        job?.createdBy.name.firstName
                    } ${
                        job?.createdBy.name.lastName
                    } on ${currentInterview?.startTime.format(
                        'dddd MMMM D, YYYY @ h:mma'
                    )}. We'll send you an email reminder the day before your interview.`}
                    primaryActionText={'See my interviews'}
                    image={offerRoleImg}
                />
            )}
            {redirect && (
                <Redirect to={`/secure/${user.userID}/upcominginterviews`} />
            )}
        </>
    );
}
