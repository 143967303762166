import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import { FirebaseContext } from '../../context/FirebaseContext';

import { TextButton } from './Button/Button';

const getFilenameFromURL = (url: string) => {
    const decodedPath = decodeURIComponent(new URL(url).pathname);
    return decodedPath.split('/').pop()?.split('?')[0] || '';
};

const FindFile = (props: {
    sx?: SxProps<Theme> | undefined;
    label: string;
    id: string;
    onClick: (name: string, file: File) => void;
    acceptPDF?: boolean;
}) => (
    <TextButton
        variant='text'
        onClick={() => {}}
        component='label'
        sx={props.sx}
    >
        <b style={{ margin: 0, lineHeight: 1 }}>{props.label}</b>
        <input
            name={props.id}
            id={props.id}
            type='file'
            hidden
            accept={props.acceptPDF ? 'application/pdf' : 'image/*'}
            onChange={(e) => props.onClick(e.target.value, e.target.files![0])}
        />
    </TextButton>
);

export default function UploadFile(props: {
    userId: string;
    value: string;
    setValue: (value: string) => void;
    id: string;
    label: string;
    acceptPDF?: boolean;
}) {
    const [filename, setFilename] = useState('');
    const { storage } = useContext(FirebaseContext);

    useEffect(() => {
        if (!filename && props.value) {
            setFilename(getFilenameFromURL(props.value));
        }
    }, [props.value, filename]);

    const uploadToFirebase = (name: string, file: File) => {
        const storageRef = storage?.ref();
        const extension = name.split('.').pop();
        const fileRef = storageRef?.child(
            `assets/${props.userId}/${props.id}.${extension}`
        );
        fileRef?.put(file).then(() => {
            fileRef.getDownloadURL().then((downloadURL) => {
                props.setValue(downloadURL);
                const splitName = name.split('\\');
                setFilename(splitName[splitName.length - 1]);
            });
        });
    };

    return (
        <Fragment>
            {!props.value && (
                <FindFile
                    id={props.id}
                    label={`Upload ${props.label}`}
                    onClick={uploadToFirebase}
                    acceptPDF={props.acceptPDF}
                />
            )}
            {props.value && (
                <Fragment>
                    <Typography
                        variant='body1'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            lineHeight: 1
                        }}
                    >
                        {filename}
                    </Typography>
                    <FindFile
                        id={`replace-${props.id}`}
                        label={'Replace'}
                        onClick={uploadToFirebase}
                        sx={{ m: 1 }}
                        acceptPDF={props.acceptPDF}
                    />
                </Fragment>
            )}
        </Fragment>
    );
}
