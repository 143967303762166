import { ThemeProvider } from '@mui/material/styles';
import firebase from 'firebase/compat/app';
import React, { useContext, useEffect, useState } from 'react';
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom';

import { checkWaitlist } from '../services/user';

import { DevCycleProvider } from './context/DevCycleContext';
import { FirebaseContext, FirebaseProvider } from './context/FirebaseContext';
import { UserContext, UserProvider } from './context/UserContext';
import AdminPage from './pages/Admin/AdminPage';
import JobPost from './pages/CreateJob/JobPost';
import { WaitlistType } from './pages/Login/Login';
import LoginRoutes from './pages/Login/LoginRoutes';
import BusinessProfile from './pages/Profile/business/BusinessProfile';
import CandidateProfile from './pages/Profile/candidate/CandidateProfile';
import CandidatesPublicPage from './public/pages/Candidates';
import EmployersPublicPage from './public/pages/Employers';
import PrivacyPolicyPage from './public/pages/PrivacyPolicy';
import ServicesAgreementPage from './public/pages/ServicesAgreement';
import TermsPage from './public/pages/Terms';
import WaitlistModal from './public/WaitlistModal';
import redirects from './redirects';
import SecureSite from './SecureSite';
import theme from './Theme';

function App({
    firebaseUser
}: {
    firebaseUser: firebase.User | null | undefined;
}) {
    const { user } = useContext(UserContext);
    const isBusiness = user.userID ? user.levylRole === 'business' : undefined;
    const [waitlistAccepted, setWaitlistAccepted] = useState<
        boolean | undefined
    >(undefined);

    useEffect(() => {
        if (user.email) {
            checkWaitlist(user.email).then((response) => {
                if (response === 'accepted') {
                    setWaitlistAccepted(true);
                } else {
                    setWaitlistAccepted(false);
                }
            });
        }
    }, [user.email]);

    return (
        <Switch>
            {redirects.map(({ path, to }) => (
                <Redirect key={path} from={path} to={to} />
            ))}
            <Route path='/public/career-seekers'>
                <CandidatesPublicPage />
            </Route>
            <Route path='/public/companies'>
                <EmployersPublicPage />
            </Route>
            <Route path='/public/terms'>
                <TermsPage />
            </Route>
            <Route path='/public/privacy-policy'>
                <PrivacyPolicyPage />
            </Route>
            <Route path='/public/services-agreement'>
                <ServicesAgreementPage />
            </Route>
            <Route path='*'>
                <Switch>
                    <Route path='/secure/:uid'>
                        {firebaseUser &&
                            user.location.termsAndConditions &&
                            waitlistAccepted && <SecureSite />}
                        {(firebaseUser === null ||
                            waitlistAccepted === false) && (
                            <Redirect to='/login' />
                        )}
                        {user.userID && !user.location.termsAndConditions && (
                            <Redirect
                                to={`/edit/${user.userID}/${user.levylRole}/location`}
                            />
                        )}
                    </Route>
                    <Route
                        path={[
                            '/create/:uid/candidate',
                            '/edit/:uid/candidate'
                        ]}
                    >
                        {firebaseUser && isBusiness === false && (
                            <CandidateProfile />
                        )}
                        {(firebaseUser === null || isBusiness) && (
                            <Redirect to='/login' />
                        )}
                    </Route>
                    <Route
                        path={[
                            '/edit/:uid/business/name',
                            '/edit/:uid/business/location'
                        ]}
                        exact
                    >
                        {firebaseUser && isBusiness && <BusinessProfile />}
                        {(firebaseUser === null || isBusiness === false) && (
                            <Redirect to='/login' />
                        )}
                    </Route>
                    <Route
                        path={[
                            '/create/:uid/business',
                            '/edit/:uid/business/:companyID'
                        ]}
                    >
                        {firebaseUser && isBusiness && <BusinessProfile />}
                        {(firebaseUser === null || isBusiness === false) && (
                            <Redirect to='/login' />
                        )}
                    </Route>
                    <Route
                        path={[
                            '/create/:uid/job/:companyID',
                            '/edit/:uid/job/:companyID/:jobID'
                        ]}
                    >
                        {firebaseUser && isBusiness && <JobPost />}
                        {(firebaseUser === null || isBusiness === false) && (
                            <Redirect to='/login' />
                        )}
                    </Route>
                    <Route
                        exact
                        path={[
                            '/login',
                            '/resetpassword',
                            '/signup/select',
                            '/signup/user',
                            '/signup/business',
                            '/signup/user/:uid',
                            '/signup/business/:uid'
                        ]}
                    >
                        <LoginRoutes
                            user={firebaseUser}
                            waitlistAccepted={waitlistAccepted}
                        />
                    </Route>
                    <Route path={'/admin'}>
                        <AdminPage />
                    </Route>
                    <Redirect exact from={'/'} to={'/public/career-seekers'} />
                    <Redirect from={'*'} to={'/login'} />
                </Switch>
            </Route>
        </Switch>
    );
}

const AppWithContext = () => {
    const [firebaseUser, setFirebaseUser] = useState<
        firebase.User | null | undefined
    >(undefined);
    const { auth } = useContext(FirebaseContext);
    const [open, setOpen] = useState('');
    const [redirectURL, setRedirectURL] = useState('');

    useEffect(() => {
        const unsubscribe = auth?.onAuthStateChanged((newUser) => {
            if (newUser) {
                checkWaitlist(newUser.email ?? '').then((response) => {
                    if (['accepted', 'waitlist_candidate'].includes(response)) {
                        setFirebaseUser(newUser);
                    } else {
                        if (response === 'waitlist_business') {
                            setOpen('onWaitlist');
                        } else if (response === 'not_found') {
                            setOpen('doesNotExist');
                        }
                        setFirebaseUser(null);
                    }
                });
            } else {
                setFirebaseUser(null);
            }
        });
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [auth]);

    useEffect(() => {
        auth?.getRedirectResult().then((userCredential) => {
            if (userCredential.user !== null) {
                checkWaitlist(userCredential.user.email ?? '').then(
                    (response) => {
                        if (
                            ['accepted', 'waitlist_candidate'].includes(
                                response
                            )
                        ) {
                            setFirebaseUser(userCredential.user);
                        } else {
                            if (response === 'waitlist_business') {
                                setOpen('onWaitlist');
                            } else if (response === 'not_found') {
                                setOpen('doesNotExist');
                            }
                            setFirebaseUser(null);
                        }
                    }
                );
            }
        });
    }, [auth]);

    const closeAndRedirect = (url: string) => {
        setOpen('');
        setRedirectURL(url);
    };

    return (
        <Router>
            <UserProvider firebaseUser={firebaseUser as firebase.User}>
                <DevCycleProvider>
                    <ThemeProvider theme={theme}>
                        <App firebaseUser={firebaseUser} />
                        {open !== '' && (
                            <WaitlistModal
                                open={open !== ''}
                                onClose={() => setOpen('')}
                                waitlistType={open as WaitlistType}
                                closeAndRedirect={(url) =>
                                    closeAndRedirect(url)
                                }
                            />
                        )}
                        {redirectURL && <Redirect to={redirectURL} />}
                    </ThemeProvider>
                </DevCycleProvider>
            </UserProvider>
        </Router>
    );
};

const FirebaseApp = () => (
    <FirebaseProvider>
        <AppWithContext />
    </FirebaseProvider>
);

export default FirebaseApp;
