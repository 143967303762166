import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Company } from '../../../../types/company';
import { TextButton } from '../../../global/components/Button/Button';
import RichText from '../../../global/components/RichText';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import {
    CheckboxWithSpacingTop,
    FormSection,
    NextSteps,
    StyledButton
} from '../styles';

import content from './content';

import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup
    .object()
    .shape({
        description: yup.string(),
        termsAndConditions: yup
            .bool()
            .oneOf([true], commonContent.location.errors.termsAndConditions)
            .required(commonContent.common.errors.required)
    })
    .required();

export default function BusinessDescription(props: {
    company: Company;
    submitCompany: (value?: Partial<Company>) => Promise<void>;
    prevURL: string;
    onClose: () => Promise<void>;
    isEdit?: boolean;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            description: props.company.description,
            termsAndConditions: false
        }
    });

    const onSubmit = (data: {
        description: string;
        termsAndConditions: boolean;
    }) => {
        if (data.termsAndConditions) {
            props.submitCompany({
                ...props.company,
                description: data.description
            });
        }
    };

    return (
        <ColumnCard
            closeScreen
            step={2}
            screenType={'business'}
            onClose={props.onClose}
            wide
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {content.company.title}
                </Typography>
                <div style={{ marginTop: '8px', marginBottom: '8px' }}>
                    <Typography>
                        <label
                            style={{ color: 'text.primary' }}
                            htmlFor='description'
                        >
                            {'Description'}
                        </label>
                    </Typography>
                    <RichText
                        id={'description'}
                        control={control as unknown as Control<FieldValues>}
                    />
                </div>
                <CheckboxWithSpacingTop
                    id='termsAndConditions'
                    label={content.company.termsAndConditions.label}
                    color='primary'
                    error={!!errors.termsAndConditions}
                    errorText={errors.termsAndConditions?.message}
                    control={control as unknown as Control<FieldValues>}
                />
                <NextSteps>
                    {!props.isEdit && (
                        <TextButton to={props.prevURL}>
                            {commonContent.common.back}
                        </TextButton>
                    )}
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id={'submit'}
                    >
                        {props.isEdit ? 'Save' : commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
