/* eslint-disable max-lines */
import CancelIcon from '@mui/icons-material/Cancel';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Modal from '../../../global/components/Modal/Modal';
import StyledCard from '../../../global/Core';

import type { SimpleInterview } from '../../../../types/interview';
import type { Job } from '../../../../types/job';

const StyledCardHeader = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
`;

const StyledCardFooter = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }
`;

const StyledJobName = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
`;

const Status = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.25rem;
`;

const OpenStatus = styled('div')`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: 0.25rem;
`;

const OpenCircle = styled('div')`
    display: flex;
    background-color: #8ef2be;
    height: 0.625rem;
    width: 0.625rem;
    border-radius: 50%;
    margin-top: 1px;
`;

const ClosedCircle = styled(OpenCircle)`
    background-color: red;
`;

const PendingCircle = styled(OpenCircle)`
    background-color: #f2d88f;
`;

const CompletedCircle = styled(OpenCircle)`
    background-color: #8f8ef2;
`;

const InterviewsBookedPill = styled('div')`
    display: flex;
    background-color: #f6f6f6;
    border-radius: 0.5rem;
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
`;

const ButtonContainer = styled('div')`
    display: flex;
    gap: 0.5rem;
    @media (max-width: 600px) {
        flex-direction: row-reverse;
    }
`;

const StyledButton = styled(Button)`
    text-transform: none;
    border-radius: 8;
`;

const getInterviewCountByStatus = (interviews: SimpleInterview[]) => {
    const interviewsByStatus = {
        available: 0,
        total_upcoming: 0,
        completed: 0
    };
    const now = moment();
    interviews.forEach((interview) => {
        if (
            [
                'available',
                'scheduled',
                'follow_up_requested',
                'reschedule_requested'
            ].includes(interview.status) &&
            interview.startTime.isAfter(now)
        ) {
            interviewsByStatus.total_upcoming += 1;
            if (interview.status === 'available') {
                interviewsByStatus.available += 1;
            }
        } else if (interview.status.startsWith('completed')) {
            interviewsByStatus.completed += 1;
        }
    });
    return interviewsByStatus;
};

const hasCompletedInterviews = (interviews: SimpleInterview[]) =>
    interviews.some((interview) => interview.status.startsWith('completed'));

export default function EmployerJobCard({
    job,
    userID,
    active,
    status,
    setJobOpen,
    activeRolesExceeded
}: {
    job: Job;
    userID: string;
    active: boolean;
    status: 'active' | 'closed' | 'pending' | 'completed';
    setJobOpen: (value: boolean) => void;
    activeRolesExceeded: boolean;
}) {
    const [open, setOpen] = useState(false);
    const interviewsByStatus = getInterviewCountByStatus(job.interviews);
    return (
        <>
            <StyledCard
                variant='outlined'
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4rem',
                    justifyContent: 'space-between',
                    paddingBottom: '1.5rem',
                    position: 'relative'
                }}
            >
                <Link
                    to={`/edit/${userID}/job/${job.companyID}/${job.id}/details`}
                    style={{ position: 'absolute', top: 10, right: 10 }}
                >
                    <IconButton color={'primary'} disabled={!active}>
                        <SettingsIcon />
                    </IconButton>
                </Link>
                <Link
                    to={`/secure/${userID}/job/${job.id}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ position: 'absolute', top: 10, right: 46 }}
                >
                    <IconButton color={'primary'} disabled={!active}>
                        <OpenInNewIcon />
                    </IconButton>
                </Link>
                {status !== 'closed' && (
                    <IconButton
                        color={'primary'}
                        style={{ position: 'absolute', top: 10, right: 82 }}
                        onClick={() => setOpen(true)}
                    >
                        <CancelIcon />
                    </IconButton>
                )}
                <StyledCardHeader>
                    <StyledJobName>
                        <Status>
                            {status === 'active' && (
                                <OpenStatus>
                                    <OpenCircle />
                                    <Typography variant={'body1'}>
                                        {'Active'}
                                    </Typography>
                                </OpenStatus>
                            )}
                            {status === 'closed' && (
                                <OpenStatus>
                                    <ClosedCircle />
                                    <Typography variant={'body1'}>
                                        {'Closed'}
                                    </Typography>
                                </OpenStatus>
                            )}
                            {status === 'pending' && (
                                <OpenStatus>
                                    <PendingCircle />
                                    <Typography variant={'body1'}>
                                        {'Pending'}
                                    </Typography>
                                </OpenStatus>
                            )}
                            {status === 'completed' && (
                                <OpenStatus>
                                    <CompletedCircle />
                                    <Typography variant={'body1'}>
                                        {'Completed'}
                                    </Typography>
                                </OpenStatus>
                            )}
                        </Status>
                        <Typography
                            variant={'h2'}
                            style={{
                                display: 'flex',
                                fontWeight: 400,
                                fontSize: '20px'
                            }}
                        >
                            {job.title}
                        </Typography>
                    </StyledJobName>
                </StyledCardHeader>
                <StyledCardFooter>
                    <InterviewsBookedPill>
                        {status === 'active' ? (
                            <Typography variant={'body1'} fontSize={16}>
                                <Typography
                                    fontSize={16}
                                    color={'primary'}
                                    component={'span'}
                                    fontWeight={500}
                                >
                                    {interviewsByStatus.available}
                                </Typography>
                                {` / ${interviewsByStatus.total_upcoming} upcoming interviews booked`}
                            </Typography>
                        ) : (
                            <Typography variant={'body1'} fontSize={16}>
                                {`${interviewsByStatus.completed} interviews completed`}
                            </Typography>
                        )}
                    </InterviewsBookedPill>
                    {status === 'active' && (
                        <ButtonContainer>
                            <Link to={`/secure/${userID}/network/${job.id}`}>
                                <StyledButton
                                    variant='outlined'
                                    disabled={
                                        !active ||
                                        !hasCompletedInterviews(job.interviews)
                                    }
                                >
                                    {'View Candidates'}
                                </StyledButton>
                            </Link>
                            <Link
                                to={`/edit/${userID}/job/${job.companyID}/${job.id}/schedule`}
                            >
                                <StyledButton
                                    variant='contained'
                                    disabled={!active}
                                >
                                    {'Create Interviews'}
                                </StyledButton>
                            </Link>
                        </ButtonContainer>
                    )}
                    {status === 'pending' && (
                        <ButtonContainer>
                            <Link
                                to={`/edit/${userID}/job/${job.companyID}/${job.id}/schedule`}
                            >
                                <StyledButton
                                    variant='outlined'
                                    disabled={!active}
                                >
                                    {'Create Interviews'}
                                </StyledButton>
                            </Link>
                            <Link to={`/secure/${userID}/network/${job.id}`}>
                                <StyledButton
                                    variant='contained'
                                    disabled={
                                        !active ||
                                        !hasCompletedInterviews(job.interviews)
                                    }
                                >
                                    {'View Candidates'}
                                </StyledButton>
                            </Link>
                        </ButtonContainer>
                    )}
                    {status === 'completed' && (
                        <ButtonContainer>
                            <Link to={`/secure/${userID}/network/${job.id}`}>
                                <StyledButton
                                    variant='outlined'
                                    disabled={!active}
                                >
                                    {'View Candidates'}
                                </StyledButton>
                            </Link>
                        </ButtonContainer>
                    )}
                    {status === 'closed' && (
                        <ButtonContainer>
                            <StyledButton
                                variant='outlined'
                                disabled={!active || activeRolesExceeded}
                                onClick={() => setJobOpen(true)}
                            >
                                {'Reopen Job'}
                            </StyledButton>
                        </ButtonContainer>
                    )}
                </StyledCardFooter>
            </StyledCard>
            <Modal
                heading={'Close this job?'}
                open={open}
                content={
                    'Are you sure you want to close this job? Closing it would mean cancelling all upcoming interviews you have for it'
                }
                onClose={() => setOpen(false)}
                primaryCTAAction={() => setJobOpen(false)}
                primaryCTAContent={'Close Job'}
            />
        </>
    );
}
