import {
    convertCompanyResponseToCompany,
    convertCompanyToCompanyResponse
} from './convert/company';
import { get, post, put } from './http';

import type { Company, CompanyResponse } from '../types/company';

export const getCompany = (companyID: number) =>
    get(`/api/company/${companyID}`).then((response: CompanyResponse) =>
        convertCompanyResponseToCompany(response)
    );

export const getUserCompanies = (userID: number) =>
    get(`/api/company/user/${userID}`).then((response: CompanyResponse[]) =>
        response.map(convertCompanyResponseToCompany)
    );

export const createCompany = (companyDict: Company) => {
    const companyResponse = convertCompanyToCompanyResponse(companyDict);
    return post('/api/company', companyResponse).then(
        (response: CompanyResponse) => convertCompanyResponseToCompany(response)
    );
};

export const updateCompany = (comapnyDict: Company) => {
    const companyResponse = convertCompanyToCompanyResponse(comapnyDict);
    return put('/api/company', companyResponse).then(
        (response: CompanyResponse) => convertCompanyResponseToCompany(response)
    );
};

export const createCompanyPaymentCheckout = (
    companyID: number,
    activeRoles: number,
    returnURL: string
) =>
    post(`/api/company/payment_checkout/${companyID}/${activeRoles}`, {
        return_url: returnURL
    });

export const updatePlan = (companyID: number, activeRoles: number) =>
    put(`/api/company/payment_checkout/${companyID}/${activeRoles}`, {});

export const manageSubscription = (companyID: number, returnURL: string) =>
    post(`/api/company/payment/manage/${companyID}`, { return_url: returnURL });

export const sendInvitations = (companyID: number, employees: string[]) =>
    post(`/api/company/send_invites/${companyID}`, employees);

export const getCompanyStatistics = (companyId: number) =>
    get(`/api/company/statistics/${companyId}`);
