import MUIButton from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import {
    connectMockCandidateToMockManager,
    createCandidateMocks,
    createCompanyMocks,
    createWaitlistCandidateMock,
    dropAllMockData,
    populateMockJobs
} from '../../../services/admin';
import Button from '../../global/components/Button/Button';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import MainContainer from '../../global/layouts/MainContainer/MainContainer';

type MockFunction =
    | 'mockUser'
    | 'mockCompany'
    | 'mockJob'
    | 'connectMockUser'
    | 'dropMockData'
    | 'mockWaitlistUser';

const mockFunctions = {
    mockUser: 'Mock User',
    mockCompany: 'Mock Company',
    mockJob: 'Populate Mock Jobs',
    connectMockUser: 'Connect Mock User to Mock Company',
    dropMockData: 'Drop Mock Data',
    mockWaitlistUser: 'Mock Waitlist User'
};

export default function AdminPage() {
    const [mockFunction, setMockFunction] =
        useState<MockFunction>('connectMockUser');
    const [apiKey, setApiKey] = useState('');
    const [candidateID, setCandidateID] = useState('');
    const [managerID, setManagerID] = useState('');
    const [candidateEmail, setCandidateEmail] = useState('');
    const [managerEmail, setManagerEmail] = useState('');
    const [jobCount, setJobCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState<any>(undefined);

    const submitRequest = () => {
        let requestPromise = Promise.resolve();
        setLoading(true);
        switch (mockFunction) {
            case 'mockUser':
                requestPromise = createCandidateMocks(
                    candidateID,
                    candidateEmail,
                    apiKey
                );
                break;
            case 'mockCompany':
                requestPromise = createCompanyMocks(
                    managerID,
                    managerEmail,
                    apiKey
                );
                break;
            case 'mockJob':
                requestPromise = populateMockJobs(jobCount, apiKey);
                break;
            case 'connectMockUser':
                requestPromise = connectMockCandidateToMockManager(
                    candidateID,
                    managerID,
                    candidateEmail,
                    managerEmail,
                    apiKey
                );
                break;
            case 'dropMockData':
                requestPromise = dropAllMockData(apiKey);
                break;
            case 'mockWaitlistUser':
                requestPromise = createWaitlistCandidateMock(apiKey);
                break;
            default:
                break;
        }
        requestPromise
            .then((response) => {
                setResult(response);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    return (
        <MainContainer
            sideBarButtons={[]}
            firstName={''}
            photoURL={''}
            signOut={() => {}}
        >
            <ColumnCard>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        gap: '1rem'
                    }}
                >
                    <Typography
                        variant='h2'
                        component='h1'
                        fontWeight='400'
                        sx={{ mb: 2 }}
                    >
                        {'Mock User Generation'}
                    </Typography>
                    <Stack
                        direction='row'
                        flexWrap={'wrap'}
                        sx={{ gap: '0.5rem' }}
                    >
                        {Object.entries(mockFunctions).map(([key, value]) => (
                            <MUIButton
                                key={key}
                                variant={
                                    key === mockFunction
                                        ? 'contained'
                                        : 'outlined'
                                }
                                sx={{
                                    textTransform: 'none',
                                    textWrap: 'nowrap'
                                }}
                                onClick={() =>
                                    setMockFunction(key as MockFunction)
                                }
                            >
                                {value}
                            </MUIButton>
                        ))}
                    </Stack>
                    <TextField
                        label='API Key'
                        variant='outlined'
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                    />
                    {['mockUser', 'connectMockUser'].includes(mockFunction) && (
                        <>
                            <TextField
                                label='Candidate ID'
                                variant='outlined'
                                value={candidateID}
                                onChange={(e) => setCandidateID(e.target.value)}
                            />
                            <TextField
                                label='Candidate Email'
                                variant='outlined'
                                value={candidateEmail}
                                onChange={(e) =>
                                    setCandidateEmail(e.target.value)
                                }
                            />
                        </>
                    )}
                    {['mockCompany', 'connectMockUser'].includes(
                        mockFunction
                    ) && (
                        <>
                            <TextField
                                label='Manager ID'
                                variant='outlined'
                                value={managerID}
                                onChange={(e) => setManagerID(e.target.value)}
                            />
                            <TextField
                                label='Manager Email'
                                variant='outlined'
                                value={managerEmail}
                                onChange={(e) =>
                                    setManagerEmail(e.target.value)
                                }
                            />
                        </>
                    )}
                    {mockFunction === 'mockJob' && (
                        <TextField
                            label='Populate Jobs Count'
                            variant='outlined'
                            type={'number'}
                            value={jobCount}
                            onChange={(e) =>
                                setJobCount(parseInt(e.target.value, 10))
                            }
                        />
                    )}
                    <Button
                        variant='contained'
                        onClick={submitRequest}
                        loading={loading}
                    >
                        {'Generate Mock Data'}
                    </Button>
                    {result?.signup_link && (
                        <div>
                            <p>{'Sign in details:'}</p>
                            <p>{`Email: ${result?.email}`}</p>
                            <p>{`Sign in link: ${result.signup_link}`}</p>
                        </div>
                    )}
                </div>
            </ColumnCard>
        </MainContainer>
    );
}
